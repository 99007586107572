import React, { useState, useEffect } from "react"

import HeaderFork from "../components/HeaderFork"
import UseWindowDimensions from "../helpers/UseWindowDimensions"

import IndexForkImg from "../assets/images/index-fork-bg.png"
import Counter from "../components/Counter"
import Transfer from "../components/Transfer"

const HomeFork = () => {
  const [tokenStatsData, setTokenStatsData] = useState({
    buyLink: "",
    price: 0,
    priceChanging: 0,
    dailyVolume: 0,
  })
  const [circulating, setCirculating] = useState(0)
  const [holders, setHolders] = useState(0)
  const [isNavigationFixed, setIsNavigationFixed] = useState(false)
  const [counterFinished, setCounterFinished] = useState(false)

  const { width } = UseWindowDimensions()

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, []) // eslint-disable-line

  const handleScroll = () => {
    const position = window.pageYOffset

    if (width > 991) {
      if (position > 50) {
        setIsNavigationFixed(true)
      }
      if (position <= 50) {
        setIsNavigationFixed(false)
      }
    } else {
      if (position > 20) {
        setIsNavigationFixed(true)
      }
      if (position <= 20) {
        setIsNavigationFixed(false)
      }
    }
  }

  useEffect(() => {
    // Realio API Supply
    const totalSupplyData = async () => {
      try {
        const res = await fetch("https://app.realio.fund/api/token?symbol=rio&filter=circulating")
        //const res = await fetch("https://app.realio.fund/api/token?symbol=rio&filter=circulating")
        const data = await res.json()
        let circulatingVal = Math.round(parseFloat(data))
        //console.log("Circulating (circulatingSupply): ", circulatingVal)
        setCirculating(circulatingVal)
      } catch (e) {
        console.error("Realio API Error: ", e)
      }
    }
    totalSupplyData()
  }, [])

  useEffect(() => {
    // Stellar Holders
    const stellarHolders = async () => {
      try {
        //const res = await fetch("https://api.stellar.expert/explorer/public/asset/RIO-GBNLJIYH34UWO5YZFA3A3HD3N76R6DOI33N4JONUOHEEYZYCAYTEJ5AK")
        const res = await fetch("https://cors.deo-apps.win/https://realio.deo-apps.win/stellar-php/")
        const data = await res.json()
        const stellarHolders = data.trustlines.funded
        setHolders((prev) => prev + stellarHolders)
        //console.log("Stellar Holders: ", stellarHolders)
      } catch (e) {
        console.log("Stellar API Error: ", e)
      }
    }
    stellarHolders()
  }, [])

  useEffect(() => {
    // Algorand Holders
    const algorandHolders = async () => {
      try {
        const res = await fetch(
          "https://cors.deo-apps.win/https://algoindexer.algoexplorerapi.io/v2/assets/2751733/balances?currency-greater-than=0&limit=0"
        )
        // const res = await fetch(
        //   "https://cors.deo-apps.win/https://mainnet-idx.algonode.network/v2/assets/2751733/balances?currency-greater-than=100000000"
        // )
        const data = await res.json()
        const algoHolders = Object.keys(data.balances).length
        setHolders((prev) => prev + algoHolders)
      } catch (e) {
        console.log("Algorand API Error: ", e)
      }
    }
    algorandHolders()
  }, [])

  useEffect(() => {
    const ethereumData = async () => {
      try {
        const res = await fetch(
          `https://cors.deo-apps.win/https://api.ethplorer.io/getAddressInfo/0xf21661D0D1d76d3ECb8e1B9F1c923DBfffAe4097?apiKey=EK-u8EDY-ZCto9Y9-LJJCm`
        )
        const dataEthereum = await res.json()
        const ethereumHolders = dataEthereum.tokenInfo.holdersCount
        //console.log("Ethereum Holders", ethereumHolders)
        setTokenStatsData({
          buyLink: "https://www.mexc.com/exchange/RIO_USDT",
          price: dataEthereum.tokenInfo.price.rate,
          priceChanging: dataEthereum.tokenInfo.price.diff,
          dailyVolume: dataEthereum.tokenInfo.price.volume24h,
        })
        setHolders((prev) => prev + ethereumHolders, 2)
      } catch (e) {
        console.error("Ethplorer API Error: ", e)
      }
    }
    ethereumData()
  }, [])

  return (
    <div className="home-fork">
      <div className={isNavigationFixed ? "home-fork-header fixed" : "home-fork-header "}>
        <div className="full-width-container">
          <HeaderFork data={tokenStatsData} holders={holders} circulating={circulating} />
        </div>
      </div>
      <div className="home-fork-body">
        <div className="container">
          <div className="home-fork-content">
            <div className="home-fork-left-col">
              <div className="home-fork-title">
                <span>Something is</span>
                coming.
              </div>
              <div className="home-fork-wrapper">
                <Counter
                  counterFinished={() => {
                    setCounterFinished(true)
                  }}
                />

                {counterFinished && <Transfer />}
              </div>

              {/* <div className="home-fork-mobile-globe">
                <span className="home-fork-mobile-title-mobile1">Fork</span>
                <img src={IndexForkImgMobile} alt="Fork the World" />
                <span className="home-fork-mobile-title-mobile2">the</span>
                <span className="home-fork-mobile-title-mobile3">World</span>
              </div> */}
            </div>

            <div className="home-fork-right-col">
              <img src={IndexForkImg} className="home-fork-globe" alt="Fork the World" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default HomeFork
