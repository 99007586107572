import Main from "./routes/Main"

function App() {
  return (
    <div className="App">
      <Main />
    </div>
  )
}

export default App
