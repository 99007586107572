import React, { useState } from "react"
import { Link } from "react-router-dom"

const PASSWORD = "RealioverseIsAwesome"

const MapPassword = ({ setAuthenticated }) => {
  const [passwordInput, setPasswordInput] = useState("")
  const [passwordError, setPasswordError] = useState(false)

  const handleSubmit = () => {
    if (passwordInput.length === 0) {
      setPasswordInput("")
      setPasswordError(true)
      setAuthenticated(false)
      return
    }
    if (passwordInput !== PASSWORD) {
      setPasswordInput("")
      setPasswordError(true)
      setAuthenticated(false)
      return
    }
    setAuthenticated(true)
    // setPasswordError setPasswordInput
  }

  return (
    <>
      <div className="map-layout-lock active" />
      <div className="map-layout-lock-password-box">
        <div className="map-layout-lock-title">LandRush Coming</div>
        <div className="map-layout-lock-subtitle">2023</div>

        <div className="map-layout-lock-input-section">
          <div className="map-layout-lock-close">
            <Link to={"/"}>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM5.92354 7.38081L7.2099 9.31484H9L6.91304 6.18441L8.91904 3H7.17391L6.05847 4.90705L4.7901 3H3L5.06897 6.10345L3.04498 9.31484H4.7901L5.92354 7.38081Z"
                />
              </svg>
            </Link>
          </div>
          <div className="map-layout-lock-head">Password</div>

          <input
            className="map-layout-lock-input"
            value={passwordInput}
            onChange={(e) => setPasswordInput(e.target.value)}
            type="password"
          />
          <div className="map-layout-lock-input-text error">{passwordError && "Error!"}</div>

          <div onClick={handleSubmit} className="map-layout-lock-btn">
            Enter
          </div>
        </div>
      </div>
    </>
  )
}
export default MapPassword
