import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
// import Home from "../pages/Home"
import HomeFork from "../pages/HomeFork"
// import Pixels from "../pages/Pixels"
import Land from "../pages/Land"
//import LandOld from "../pages/LandOld"

const Main = () => {
  return (
    <Router>
      <Routes>
        {/* <Route path="/land/:id" caseSensitive={false} element={<Land />} /> */}
        {/* <Route path="/pixels" caseSensitive={false} element={<Pixels />} /> */}
        {/* <Route path="/land-old" caseSensitive={false} element={<LandOld />} /> */}
        <Route path="/land" caseSensitive={false} element={<Land />} />
        {/* <Route path="/" caseSensitive={false} element={<Home />} /> */}
        <Route path="/" caseSensitive={false} element={<HomeFork />} />
      </Routes>
    </Router>
  )
}

export default Main
