import React, { useState, useEffect } from "react"
import { ethers } from "ethers"
import { serializeError } from "eth-rpc-errors"

import "./SearchDetails.scss"

import placeIcon from "../../../assets/images/place-icon.svg"
import moneyIcon from "../../../assets/images/money-icon.svg"
import ownerIcon from "../../../assets/images/owner-icon.svg"
import plusIcon from "../../../assets/images/plus-icon.svg"
import buyIcon from "../../../assets/images/buy-icon.svg"

const SearchDetails = ({
  latLon,
  selectedPixelsCount,
  placename,
  ethPrice,
  //rioPrice,
  //selectedPixelsId,
  clearSelectedPixels,
  //selectionAreaIds,
  selectionCellIds,
  purchaseInformation,
  notificationIsLoading,
  authenticated,
}) => {
  const [priceSectionIsVisible, setPriceSectionIsVisible] = useState(false)
  const [priceRIO, setPriceRIO] = useState([])
  const [selectedCell, setSelectedCell] = useState([])
  const [regions, setRegions] = useState([])
  const [signer, setSigner] = useState(null)
  //const [provider, setProvider] = useState(null)
  const [token, seToken] = useState(null)
  const [landNFT, setLandNFT] = useState(null)
  const [data, setData] = useState({})
  const [abi, setAbi] = useState({})
  const [selectedPixelsNumber, setSelectedPixelsNumber] = useState(0)
  const [latLonCoordinates, setLatLonCoordinates] = useState([])
  const [placeNameDetails, setPlaceNameDetails] = useState(" ")

  const priceInRio = 200
  const priceInETH = 0.01
  // const priceUSDT = 10
  const landContract = "0xeb5f6f59fbb13b9778c591f7ab9336881ee920ee"
  const mockRIO = "0x32E0b53B799cC14c455011fE3458306f89aee848"
  const etherscan = "https://api-rinkeby.etherscan.io"
  const etherscanApiKey = "P5ZF6EE8GGR621H6NBE1DTCXTHM5FG7FJV"

  useEffect(() => {
    setSelectedPixelsNumber(selectedPixelsCount)
  }, [selectedPixelsCount])

  useEffect(() => {
    setLatLonCoordinates(latLon)
  }, [latLon])

  useEffect(() => {
    setPlaceNameDetails(placename)
  }, [placename])

  useEffect(() => {
    if (!selectionCellIds.length) return
    setSelectedCell(selectionCellIds)
  }, [selectionCellIds])

  useEffect(() => {
    if (!selectedCell.length) return
    let regionArray = []
    for (let i = 0; i < selectedCell.length; i++) {
      regionArray.push(selectedCell[i][0])
    }
    setRegions(regionArray)
  }, [selectedCell])

  useEffect(() => {
    if (!regions.length) return
    console.log("Regions in SearchDetails", regions)
  }, [regions])

  //console.log("selected Area id: ", selectionAreaIds)
  //console.log("-> selected Cell id (SearchDetails): ", selectionCellIds)
  //let newSelection = selectionAreaIds.toString().replace(/./g, "")
  //console.log("selected Area id ", newSelection)
  // var regions = []
  // for (let i = 0; i < selectionCellIds.length; i++) {
  //   regions.push(Math.floor(reduce(selectionCellIds[i]) * 1000000000000))
  // }
  // -7420292321091164,4065827364398637,-7419842660909302,4065827364398637,-7419842660909302,4066097160507754,-7420292321091164,4066097160507754
  // -170882992,-170903562,1597257718,1597278288
  // function reduce(x) {
  //   return (10 * Math.log(x)) / Math.LN10
  // }
  //console.log("regions0", regions)
  //console.log("selectionCellIds", selectionCellIds)
  //console.log("selectionAreaIds", selectionAreaIds)
  //console.log("selectedPixelsId", selectedPixelsId)
  // 115792089237316195423570985008687907853269984665640564039457584007913129639935
  //console.log("rioPrice", rioPrice)
  //console.log("ethPrice", ethPrice)
  //console.log("selected Area id Big Number ", newSelection)
  //console.log("selected Area id Temp Big Number ", regions)

  const clearHandle = () => {
    clearSelectedPixels()
    setRegions([])
    setSelectedPixelsNumber(0)
    setLatLonCoordinates([0, 0])
    setPlaceNameDetails(" ")
    //setLatLonDetails(false)
  }

  // eslint-disable-next-line

  useEffect(() => {
    if (!regions.length) return

    const abi = [
      {
        inputs: [
          { internalType: "address", name: "_devFund", type: "address" },
          { internalType: "address", name: "_landBank", type: "address" },
          { internalType: "uint256", name: "_price", type: "uint256" },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
      },
      { inputs: [], name: "ApprovalCallerNotOwnerNorApproved", type: "error" },
      { inputs: [], name: "ApprovalQueryForNonexistentToken", type: "error" },
      { inputs: [], name: "ApproveToCaller", type: "error" },
      { inputs: [], name: "BalanceQueryForZeroAddress", type: "error" },
      { inputs: [], name: "CannotSetAddressZero", type: "error" },
      { inputs: [], name: "ComissionOutOfAllowedRange", type: "error" },
      { inputs: [], name: "InsufficientBalance", type: "error" },
      { inputs: [], name: "MaxTilesReached", type: "error" },
      { inputs: [], name: "MintERC2309QuantityExceedsLimit", type: "error" },
      { inputs: [], name: "MintToZeroAddress", type: "error" },
      { inputs: [], name: "MintZeroQuantity", type: "error" },
      { inputs: [], name: "NoTilesSelected", type: "error" },
      { inputs: [], name: "NonExistentTokenURI", type: "error" },
      { inputs: [], name: "NotAuthorised", type: "error" },
      { inputs: [], name: "OwnerQueryForNonexistentToken", type: "error" },
      { inputs: [], name: "OwnershipNotInitializedForExtraData", type: "error" },
      { inputs: [], name: "RegionAlreadyOwned", type: "error" },
      { inputs: [], name: "TransferCallerNotOwnerNorApproved", type: "error" },
      { inputs: [], name: "TransferFailed", type: "error" },
      { inputs: [], name: "TransferFromIncorrectOwner", type: "error" },
      { inputs: [], name: "TransferToNonERC721ReceiverImplementer", type: "error" },
      { inputs: [], name: "TransferToZeroAddress", type: "error" },
      { inputs: [], name: "URIQueryForNonexistentToken", type: "error" },
      {
        anonymous: false,
        inputs: [
          { indexed: true, internalType: "address", name: "newAdmin", type: "address" },
          { indexed: true, internalType: "address", name: "oldAdmin", type: "address" },
        ],
        name: "AdminChanged",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, internalType: "address", name: "owner", type: "address" },
          { indexed: true, internalType: "address", name: "approved", type: "address" },
          { indexed: true, internalType: "uint256", name: "tokenId", type: "uint256" },
        ],
        name: "Approval",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, internalType: "address", name: "owner", type: "address" },
          { indexed: true, internalType: "address", name: "operator", type: "address" },
          { indexed: false, internalType: "bool", name: "approved", type: "bool" },
        ],
        name: "ApprovalForAll",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, internalType: "uint256", name: "newCommission", type: "uint256" },
          { indexed: true, internalType: "uint256", name: "oldCommission", type: "uint256" },
        ],
        name: "CommissionRateChanged",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, internalType: "uint256", name: "fromTokenId", type: "uint256" },
          { indexed: false, internalType: "uint256", name: "toTokenId", type: "uint256" },
          { indexed: true, internalType: "address", name: "from", type: "address" },
          { indexed: true, internalType: "address", name: "to", type: "address" },
        ],
        name: "ConsecutiveTransfer",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [{ indexed: true, internalType: "bool", name: "paused", type: "bool" }],
        name: "ContractPaused",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [{ indexed: true, internalType: "bool", name: "paused", type: "bool" }],
        name: "ContractUnpaused",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, internalType: "address", name: "newDevFund", type: "address" },
          { indexed: true, internalType: "address", name: "oldDevFund", type: "address" },
        ],
        name: "DevFundChanged",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, internalType: "address", name: "newLandBank", type: "address" },
          { indexed: true, internalType: "address", name: "oldLandBank", type: "address" },
        ],
        name: "LandBankChanged",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, internalType: "address", name: "buyer", type: "address" },
          { indexed: true, internalType: "uint256[]", name: "region", type: "uint256[]" },
        ],
        name: "LandSold",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, internalType: "address", name: "previousOwner", type: "address" },
          { indexed: true, internalType: "address", name: "newOwner", type: "address" },
        ],
        name: "OwnershipTransferred",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [{ indexed: false, internalType: "address", name: "account", type: "address" }],
        name: "Paused",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, internalType: "address", name: "from", type: "address" },
          { indexed: true, internalType: "address", name: "to", type: "address" },
          { indexed: true, internalType: "uint256", name: "tokenId", type: "uint256" },
        ],
        name: "Transfer",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [{ indexed: false, internalType: "address", name: "account", type: "address" }],
        name: "Unpaused",
        type: "event",
      },
      {
        inputs: [],
        name: "MAX_TILE_NUM",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "WETH",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "admin",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "to", type: "address" },
          { internalType: "uint256", name: "tokenId", type: "uint256" },
        ],
        name: "approve",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "owner", type: "address" }],
        name: "balanceOf",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "baseURI",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "commissionRate",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "devFund",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        name: "firstOwners",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_amountIn", type: "uint256" },
          { internalType: "address[]", name: "path", type: "address[]" },
        ],
        name: "getAmountOutMin",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
        name: "getApproved",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
        name: "getTokenPrice",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "owner", type: "address" },
          { internalType: "address", name: "operator", type: "address" },
        ],
        name: "isApprovedForAll",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        name: "isOwned",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "landBank",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256[]", name: "region", type: "uint256[]" },
          { internalType: "uint256", name: "rioAmount", type: "uint256" },
        ],
        name: "mint",
        outputs: [],
        stateMutability: "payable",
        type: "function",
      },
      {
        inputs: [],
        name: "name",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "nextId",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "owner",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
        name: "ownerOf",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
      },
      { inputs: [], name: "pause", outputs: [], stateMutability: "nonpayable", type: "function" },
      {
        inputs: [],
        name: "paused",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "price",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      { inputs: [], name: "renounceOwnership", outputs: [], stateMutability: "nonpayable", type: "function" },
      {
        inputs: [
          { internalType: "address", name: "from", type: "address" },
          { internalType: "address", name: "to", type: "address" },
          { internalType: "uint256", name: "tokenId", type: "uint256" },
        ],
        name: "safeTransferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "from", type: "address" },
          { internalType: "address", name: "to", type: "address" },
          { internalType: "uint256", name: "tokenId", type: "uint256" },
          { internalType: "bytes", name: "_data", type: "bytes" },
        ],
        name: "safeTransferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "_newAdmin", type: "address" }],
        name: "setAdmin",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "operator", type: "address" },
          { internalType: "bool", name: "approved", type: "bool" },
        ],
        name: "setApprovalForAll",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "_commissionRate", type: "uint256" }],
        name: "setCommissionRate",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "address payable", name: "_devFund", type: "address" }],
        name: "setDevFund",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "address payable", name: "_landBank", type: "address" }],
        name: "setLandBank",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "_price", type: "uint256" }],
        name: "setPrice",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
        name: "supportsInterface",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "symbol",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "tilesBought",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
        name: "tokenURI",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "totalSupply",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "from", type: "address" },
          { internalType: "address", name: "to", type: "address" },
          { internalType: "uint256", name: "tokenId", type: "uint256" },
        ],
        name: "transferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      { inputs: [], name: "unpause", outputs: [], stateMutability: "nonpayable", type: "function" },
      { stateMutability: "payable", type: "receive" },
    ]

    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    const token = new ethers.Contract(mockRIO, abi, signer)
    // eslint-disable-next-line
    const landNFT = new ethers.Contract(landContract, abi, signer)
    const data = {
      //region: ["103,1", "202,1", "302,1", "402,1"],
      region: regions,
      price: ethers.utils.parseEther(priceInRio.toString()).mul(ethers.BigNumber.from(selectedPixelsNumber)),
      priceETH: ethers.utils.parseEther(priceInETH.toString()).mul(ethers.BigNumber.from(selectedPixelsNumber)),
    }
    //setProvider(provider)
    setSigner(signer)
    seToken(token)
    setLandNFT(landNFT)
    setData(data)
    setAbi(abi)
    // }, [regions])

    // useEffect(() => {
    const getRioPrice = async () => {
      const a = await landNFT.getTokenPrice(ethers.utils.parseEther(priceInETH.toString()).mul(ethers.BigNumber.from(selectedPixelsNumber)))
      setPriceRIO(ethers.utils.formatEther(ethers.BigNumber.from(a)))
    }
    getRioPrice()
    //}, [selectedPixelsNumber, landNFT, regions])
  }, [selectedPixelsNumber, regions])

  const buyHandleRIO = async () => {
    if (!authenticated) return
    let nowDate = new Date()
    purchaseInformation({
      status: "loading",
      message: "Please wait....",
      date: nowDate.toLocaleString(),
      tx: null,
    })
    const priceRIO = ethers.BigNumber.from(
      await landNFT.getTokenPrice(ethers.utils.parseEther(priceInETH.toString()).mul(ethers.BigNumber.from(selectedPixelsNumber)))
    )

    console.log("Regions in buyHandleRIO", regions)
    console.log("Price in RIO", ethers.utils.formatEther(priceRIO))

    try {
      await token.approve(landContract, priceRIO)
      nowDate = new Date()
      purchaseInformation({
        status: "ok",
        message: "purchase Ok (Token Approve)",
        date: nowDate.toLocaleString(),
        tx: null,
      })
    } catch (e) {
      console.error("RIO token.approve Error:", e)
      nowDate = new Date()
      purchaseInformation({
        status: "fail",
        message: "purchase failed (Token Approve)",
        date: nowDate.toLocaleString(),
        tx: null,
      })
    }

    try {
      await landNFT.mint(data.region, priceRIO)
      nowDate = new Date()
      purchaseInformation({
        status: "ok",
        message: "purchase Ok! (Token Mint)",
        date: nowDate.toLocaleString(),
        tx: null,
      })
      clearHandle()
    } catch (e) {
      console.error("RIO landNFT.mint Error:", e)
      nowDate = new Date()
      purchaseInformation({
        status: "fail",
        message: "purchase failed (Token Mint)",
        date: nowDate.toLocaleString(),
        tx: null,
      })
    }
  }

  const buyHandleETH = async () => {
    if (!authenticated) return
    const landNFT = new ethers.Contract(landContract, abi, signer)
    console.log("New ETH Price", ethers.utils.formatEther(await data.priceETH))
    console.log("New ETH Price in wei", await data.priceETH)
    console.log("New RIO Price", ethers.utils.formatEther(await data.price))
    console.log("Regions in buyHandleETH", regions)
    notificationIsLoading(true)
    let nowDate = new Date()
    // purchaseInformation({
    //   status: "loading",
    //   message: "Please wait....",
    //   date: nowDate.toLocaleString(),
    //   tx: null,
    // })

    try {
      await landNFT.mint(data.region, 0, { value: data.priceETH }).then((response) => {
        //console.log("buyHandleETH data.region:", data.region)
        //console.log("buyHandleETH Response:", response.hash)
        nowDate = new Date()
        const responseHash = response.hash
        // purchaseInformation({
        //   status: "ok",
        //   message: `Purchase was successful!`,
        //   date: nowDate.toLocaleString(),
        //   tx: responseHash,
        // })
        clearHandle()

        let transaction = null

        const transactionFunction = async () => {
          const response = await fetch(
            `${etherscan}/api?module=transaction&action=getstatus&txhash=${responseHash}&apikey=${etherscanApiKey}`
          ).then((response) => response.json())
          nowDate = new Date()
          console.log("response.status", response.status)
          console.log("response.result.isError", response.result.isError)
          if (parseInt(response.status) === 1) {
            if (parseInt(response.result.isError) === 1) {
              purchaseInformation({
                status: "fail",
                message: `Error: ${response.result.errDescription}`,
                date: nowDate.toLocaleString(),
                tx: responseHash,
              })
              notificationIsLoading(false)
              clearInterval(transaction)
            }
            purchaseInformation({
              status: "ok",
              message: `Purchase was successful`,
              date: nowDate.toLocaleString(),
              tx: responseHash,
            })
            notificationIsLoading(false)
            clearInterval(transaction)
          }

          console.log("response", response)
        }
        transaction = setInterval(transactionFunction, 2000)
      })
    } catch (metamaskError) {
      const serializedError = serializeError(metamaskError)
      console.log("ETH landNFT.mint Error:", serializedError["data"])
      notificationIsLoading(false)
      purchaseInformation({
        status: "fail",
        message: `Error: ${serializedError["data"]["originalError"]["reason"]}`,
        date: nowDate.toLocaleString(),
        tx: null,
      })
    }
    // catch (e) {
    //   nowDate = new Date()
    //   purchaseInformation({
    //     status: "fail",
    //     message: "purchase failed (Token mint)",
    //     date: nowDate.toLocaleString(),
    //     tx: null,
    //   })

    //   console.error("ETH landNFT.mint Error:", e)
    // }
  }

  return (
    <div className="search-details ">
      <div className="search-details-top">
        <div className="details-column">
          <div className="details-section">
            <div className="details-title">Unnamed place</div>
            {/* <div className="details-parcels">{selectedPixelsNumber} parcels selected</div> */}
            <div className="details-parcels">{selectedPixelsNumber} parcels selected</div>
            <div className="details-address">
              <div className="details-icon">
                <img src={placeIcon} alt="" />
              </div>

              <div className="details-content">
                <div className="details-name">{latLonCoordinates ? placeNameDetails : " "}</div>
                <div className="details-coordinates">
                  Lat: {latLonCoordinates?.length ? parseFloat(latLonCoordinates[1]).toFixed(3) : 0}, Lon:{" "}
                  {latLonCoordinates?.length ? parseFloat(latLonCoordinates[0]).toFixed(3) : 0}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="details-column">
          <div className="details-section">
            <div className="details-price-line">
              <img src={moneyIcon} alt="" />
              <div className="details-price-section">
                <div className="details-price-row">
                  <span className="details-price">{parseFloat(priceInETH * selectedPixelsNumber).toFixed(2)}</span>
                  <span className="details-currency">eth</span>
                </div>
                <div className="details-price-parcel">Price per parcel: {priceInETH} ETH</div>
              </div>
            </div>
            <div className="details-owner-line">
              <img src={ownerIcon} alt="" />
              <div className="details-owner">No current owner</div>
            </div>
          </div>
        </div>

        <div className="details-column">
          <div className="details-section">
            <div className="details-related">
              <div className="details-icon">
                <img src={plusIcon} alt="" />
              </div>
              <div className="details-section-related">
                <div className="details-related-icon">Related places:</div>
                <div className="details-related-text"></div>
              </div>
            </div>
          </div>
          <div className="details-buy-btn">
            <div className="details-buy-btn-out">
              <div className="details-buy-btn-inner">
                <div className="details-buy-btn-flex">
                  <img src={buyIcon} alt="" />
                  <div onClick={() => setPriceSectionIsVisible(true)} className="details-buy-btn-text">
                    Add to Cart
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="details-clear-section">
        <div onClick={clearHandle} className="details-clear">
          clear selected
        </div>
      </div>

      {priceSectionIsVisible && (
        <>
          <div className="details-currency-section">
            <div className="details-currency-table">
              <div className="details-currency-table-line">
                <div className="details-currency-table-left">Price in rio</div>
                {/* <div className="details-currency-table-right"> {priceUSDT * selectedPixelsNumber} USD</div> */}
                <div className="details-currency-table-right"> {selectedPixelsNumber ? parseFloat(priceRIO).toFixed(2) : 0} RIO</div>
              </div>
              <div className="details-currency-table-line">
                <div className="details-currency-table-left">price in usd</div>
                <div className="details-currency-table-right">
                  {/* {ethPrice} */}
                  {/* {selectedPixelsNumber ? parseFloat((priceInRio * selectedPixelsNumber * rioPrice) / ethPrice).toFixed(4) : 0} ETH */}
                  {selectedPixelsNumber ? parseFloat(priceInETH * selectedPixelsNumber * ethPrice).toFixed(2) : 0} USD
                </div>
              </div>
              {/* <div className="details-currency-table-line">
                <div className="details-currency-table-left">RIO PRICE (IN USD)</div>

                <div className="details-currency-table-right">$ 0 USD</div>
              </div> */}
            </div>
            <div className="details-currency-amount">
              <div>Total</div>
              {/* <div>{selectedPixelsNumber ? parseFloat((selectedPixelsNumber * priceUSDT) / rioPrice).toFixed(2) : 0} RIO</div> */}
              <div>{selectedPixelsNumber ? parseFloat(selectedPixelsNumber * priceInETH).toFixed(4) : 0} ETH</div>
            </div>
          </div>
          <div className="details-buy-section">
            <div className="details-buy-btn">
              <div className="details-buy-btn-out">
                <div className="details-buy-btn-inner buy-rio">
                  <div className="details-buy-btn-flex">
                    <img src={buyIcon} alt="" />
                    <div onClick={buyHandleRIO} className="details-buy-btn-text">
                      Buy with RIO
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="details-buy-btn">
              <div className="details-buy-btn-out out-buy-eth">
                <div className="details-buy-btn-inner buy-eth">
                  <div className="details-buy-btn-flex">
                    <img src={buyIcon} alt="" />
                    <div onClick={buyHandleETH} className="details-buy-btn-text">
                      Buy with ETH
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
export default SearchDetails
