import React from "react"

//import { Wrapper, Status } from "@googlemaps/react-wrapper"
//import LandMap from "../layouts/LandMap.back"
//import LandMap from "../layouts/LandMap"
//import Map from "../layouts/Map"
//import { mapApiKey } from "../settings"
// import axios from "axios"
// import { useParams } from "react-router-dom"
// import Lottie from "lottie-react"
// import loaderAnimation from "../images/loader2.json"
import MapView from "../components/layouts/Map"
//import MapView from "../components/layouts/MapL"

// const render = (status) => {
//   console.log("Map loading status: ", status)
//   if (status === Status.LOADING) return <Lottie animationData={loaderAnimation} loop autoplay />
//   if (status === Status.FAILURE) return <Lottie animationData={loaderAnimation} loop autoplay />
//   return null
// }

//const landsDataURL = "../../data/lands.json"

const Land = () => {
  //   const { id } = useParams()
  //   const [landData, setLandData] = useState(false)
  //   const [loading, setLoading] = useState(true)

  //   useEffect(() => {
  //     const fetchData = async () => {
  //       setLoading(true)
  //       try {
  //         await axios.get(landsDataURL).then((response) => {
  //           const data = response.data

  //           if (data) {
  //             let filteredLandData = data.features.filter((item) => {
  //               return Number(item.properties.id) === Number(id)
  //             })
  //             console.log("filteredLandData: ", filteredLandData)
  //             setLandData(filteredLandData)
  //           }
  //         })
  //       } catch (error) {
  //         console.error("Error:", error.message)
  //       }
  //       setLoading(false)
  //     }
  //     fetchData()
  //   }, [id])

  //   if (!id) return false // todo: redirect to INDEX
  //   if (!landData) return false // todo: redirect to INDEX

  //   // const center = {
  //   //   lat: landData[0].properties.center.lat,
  //   //   lng: landData[0].properties.center.lng,
  //   // }
  //   // const zoom = landData[0].properties.zoom
  //   // const mapTypeId = "satellite"
  //   // const objectCoords = landData[0].properties.objectCoords
  //   // const landDescription = {
  //   //   title: landData[0].properties.title,
  //   //   address: landData[0].properties.address,
  //   //   lat: landData[0].properties.lat,
  //   //   lng: landData[0].properties.lng,
  //   //   price: landData[0].properties.price,
  //   //   currency: landData[0].properties.currency,
  //   //   owner: landData[0].properties.owner,
  //   //   related: landData[0].properties.related,
  //   // }

  return (
    // <div style={{ display: "flex", flex: 1 }} data-testid="land-page">
    //   <MapView />
    // </div>
    <div data-testid="land-page">
      <MapView />
    </div>
    // <>
    //   {loading ? (
    //     <Lottie animationData={loaderAnimation} loop autoplay />
    //   ) : (
    //     // <Wrapper apiKey={mapApiKey} render={render}>
    //     /* <LandMap landDescription={landDescription} center={center} zoom={zoom} mapTypeId={mapTypeId} objectCoords={objectCoords} /> */
    //     <MapView />
    //     // </Wrapper>
    //   )}
    // </>
  )
}
export default Land
