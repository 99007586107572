import React, { useState, useEffect } from "react"

//import Logo from "../images/logo-floating.svg"
//import { initWeb3Onboard } from './hooks/WalletServices'

import {
  //useAccountCenter,
  useConnectWallet,
  //useNotifications,
  //useSetChain,
  useWallets,
  //useSetLocale
} from "@web3-onboard/react"
import { initWeb3Onboard } from "../../helpers/WalletServices"

import axios from "axios"
import { mapboxApiKey } from "../../settings"
import SearchDetails from "./sections/SearchDetails"
import svgSprites from "../../svgSprites"
import notificationSuccessIcon from "../../assets/images/notification-success-icon.svg"
import notificationFailIcon from "../../assets/images/notification-fail-icon.svg"
import loaderIcon from "../../assets/images/spinner-loader.svg"

//import { useAccountCenter, useConnectWallet, useNotifications, useSetChain, useWallets, useSetLocale } from "@web3-onboard/react"
//import './App.css'
//import Header from './views/Header/Header.js'
//import Footer from './views/Footer/Footer.js'

// if (window.innerWidth < 700) {
//   new VConsole()
// }

// let provider

// const internalTransferABI = [
//   {
//     inputs: [
//       {
//         internalType: "address payable",
//         name: "to",
//         type: "address",
//       },
//     ],
//     name: "internalTransfer",
//     outputs: [],
//     stateMutability: "payable",
//     type: "function",
//   },
// ]

// let internalTransferContract

// import { init, useConnectWallet } from "@web3-onboard/react"
// import injectedModule from "@web3-onboard/injected-wallets"
// import walletConnectModule from "@web3-onboard/walletconnect"
// //import coinbaseModule from "@web3-onboard/coinbase"
// //import gnosisModule from "@web3-onboard/gnosis"
// import { ethers } from "ethers"

// const injected = injectedModule()
// //const coinbase = coinbaseModule()
// const walletConnect = walletConnectModule()

//const ledger = ledgerModule()
//const gnosis = gnosisModule()

// const infuraKey = "4e2de4ac9de7455d81148177682ef6b7"
// const rpcUrl = `https://mainnet.infura.io/v3/${infuraKey}`

// initialize Onboard
// init({
//   wallets: [injected, walletConnect],
//   chains: [
//     {
//       id: "0x44e13612e9a8b36B32CDEbF7a8D2F5175d95d725",
//       token: "ETH",
//       label: "Ethereum Mainnet",
//       rpcUrl,
//     },
//   ],
//   appMetadata: {
//     icon: Logo,
//     name: "Blocknative Web3-Onboard",
//     description: "Demo app for Web3-Onboard",
//     recommendedInjectedWallets: [
//       { name: "Coinbase", url: "https://wallet.coinbase.com/" },
//       { name: "MetaMask", url: "https://metamask.io" },
//     ],
//     agreement: {
//       version: "1.0.0",
//       termsUrl: "https://www.blocknative.com/terms-conditions",
//       privacyUrl: "https://www.blocknative.com/privacy-policy",
//     },
//     gettingStartedGuide: "https://blocknative.com",
//     explore: "https://blocknative.com",
//   },
//   accountCenter: {
//     desktop: {
//       position: "topRight",
//       enabled: true,
//       minimal: false,
//     },
//   },
// })

// import { ethers } from "ethers"
// import { initWeb3Onboard } from "../hooks/WalletServices"
// import { useAccountCenter, useConnectWallet, useNotifications, useSetChain, useWallets, useSetLocale } from "@web3-onboard/react"
// let provider

const SearchSection = ({
  walletModal,
  latLon,
  setSearchQuery,
  sendCoordinates,
  sendCountryName,
  selectedArea,
  //latLon,
  selectedPixelsCount,
  clearSelectedPixels,
  selectionAreaIds,
  selectionCellIds,
  cellsSelectionStarted,
  authenticated,
}) => {
  const [{ wallet }, connect, disconnect] = useConnectWallet()

  const [searchPlaceholderHidden, setSearchPlaceholderHidden] = useState(false)
  const [searchInputHidden, setSearchInputHidden] = useState(true)
  //const [searching, setSearching] = useState(false)
  const [pixelDetailIsVisible, setPixelDetailIsVisible] = useState(false)
  const [query, setQuery] = useState()
  const [walletConnected, setWalletConnected] = useState(false) // eslint-disable-line no-unused-vars
  const [suggestions, setSuggestions] = useState([])
  const [latLonSearchDetails, setLatLonSearchDetails] = useState(latLon)
  const [placenameDetails, setPlacenameDetails] = useState("") // eslint-disable-line no-unused-vars
  const [ethPrice, setEthPrice] = useState(0) // eslint-disable-line no-unused-vars
  const [rioPrice, setRioPrice] = useState(0) // eslint-disable-line no-unused-vars
  const [selectedPixelsId, setSelectedPixelsId] = useState([1, 2, 3]) // eslint-disable-line no-unused-vars
  const [notificationIsVisible, setNotificationIsVisible] = useState(false) // eslint-disable-line no-unused-vars
  const [notificationIsLoading, setNotificationIsLoading] = useState(false) // eslint-disable-line no-unused-vars
  const [notificationData, setNotificationData] = useState([]) // eslint-disable-line no-unused-vars
  const [hoverIndex, setHoverIndex] = useState(null)

  const etherscan = "https://rinkeby.etherscan.io/tx/"

  //let latLonSearchDetails = []

  // useEffect(() => {
  //   if (!latLon) return
  //   console.log("setLatLonSearchDetails-bom!")
  //   setLatLonSearchDetails(latLon)
  // })

  //console.log("- selectedPixelsCount:", selectedPixelsCount)
  //console.log("- selectionAreaIds:", selectionAreaIds)
  //console.log("--> selectionCellIds (SearchSection):", selectionCellIds)

  useEffect(() => {
    if (!latLon) return
    setPixelDetailIsVisible(true)
  }, [latLon])

  // eslint-disable-next-line
  useEffect(() => {
    if (!latLon) return
    if (latLonSearchDetails === latLon) return

    //const coordinates = `${latLon.long},${latLon.lat}`
    const coordinates = `${latLon[0]},${latLon[1]}`
    // MapBox geo data
    const geoData = async () => {
      //console.log("geoData: ")
      try {
        axios
          .get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${coordinates}.json?access_token=${mapboxApiKey}`)
          .then(function (response) {
            setPlacenameDetails(response?.data.features[0].place_name)
          })
          .catch(function (error) {
            console.log("MapBox API error: ", error)
          })
        setLatLonSearchDetails(latLon)
      } catch (error) {
        console.error("MapBox API Error: ", error)
      }
    }
    const coinPrice = async () => {
      try {
        axios
          .get(
            `https://cors.deo-apps.win/https://api.ethplorer.io/getAddressInfo/0xf21661D0D1d76d3ECb8e1B9F1c923DBfffAe4097?apiKey=EK-u8EDY-ZCto9Y9-LJJCm`
          )
          .then(function (response) {
            const ethPrice = response?.data.ETH.price.rate
            const rioPrice = response?.data.tokenInfo.price.rate
            setEthPrice(ethPrice)
            setRioPrice(rioPrice)
          })
          .catch(function (error) {
            console.log("Ethplorer API error: ", error)
          })
      } catch (error) {
        console.error("Ethplorer API Error: ", error)
      }
    }

    coinPrice()
    geoData()
    setLatLonSearchDetails(latLon)
  })
  //}
  //latLonSearchDetails = latLon
  //setLatLonSearchDetails(latLon)

  //console.log("SelectedArea: ", selectedArea)

  //const [value, setValue] = useState(initialValue);
  //const [suggestions, setSuggestions] = useState([])

  // const handleChange = async (event) => {
  //   setValue(event.target.value);

  //   try {
  //     const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${event.target.value}.json?access_token=pk.eyJ1Ijoic3RoeW1hIiwiYSI6ImNrcnFycDlzNjFxM3Uydm1vMGNxd200amsifQ.aTXBxeiEvrCesxbO8OuFEg&autocomplete=true`;
  //     const response = await fetch(endpoint);
  //     const results = await response.json();
  //     setSuggestions(results?.features);
  //   } catch (error) {
  //     console.log("Error fetching data, ", error);
  //   }
  // };

  // return {
  //   value,
  //   onChange: handleChange,
  //   setValue,
  //   suggestions,
  //   setSuggestions
  // };

  //const [pixelSelected, setPixelSelected] = useState(latLon)

  // const [{ wallet }, connect, disconnect, updateBalances, setWalletModules] = useConnectWallet()
  // const [{ chains, connectedChain, settingChain }, setChain] = useSetChain()
  // const [notifications, customNotification, updateNotify] = useNotifications()
  // const connectedWallets = useWallets()
  // const updateAccountCenter = useAccountCenter()
  // const updateLocale = useSetLocale()

  // const [web3Onboard, setWeb3Onboard] = useState(null)

  // const [toAddress, setToAddress] = useState("")
  // const [accountCenterPosition, setAccountCenterPosition] = useState("topRight")
  // const [notifyPosition, setNotifyPosition] = useState("bottomRight")
  // const [locale, setLocale] = useState("en")
  // const [accountCenterSize, setAccountCenterSize] = useState("normal")
  // const [accountCenterExpanded, setAccountCenterExpanded] = useState(false)

  // useEffect(() => {
  //   setWeb3Onboard(initWeb3Onboard)
  //   console.log("initWeb3Onboard ", initWeb3Onboard)
  // }, [])

  // useEffect(() => {
  //   console.log("notifications ", notifications)
  // }, [notifications])

  // useEffect(() => {
  //   if (!connectedWallets.length) return

  //   const connectedWalletsLabelArray = connectedWallets.map(({ label }) => label)
  //   window.localStorage.setItem("connectedWallets", JSON.stringify(connectedWalletsLabelArray))
  // }, [connectedWallets, wallet])

  // useEffect(() => {
  //   if (!wallet?.provider) {
  //     provider = null
  //   } else {
  //     provider = new ethers.providers.Web3Provider(wallet.provider, "any")

  //     // internalTransferContract = new ethers.Contract(
  //     //   "0xb8c12850827ded46b9ded8c1b6373da0c4d60370",
  //     //   internalTransferABI,
  //     //   provider.getUncheckedSigner()
  //     // )
  //   }
  // }, [wallet])

  // useEffect(() => {
  //   const previouslyConnectedWallets = JSON.parse(window.localStorage.getItem("connectedWallets"))

  //   // if (previouslyConnectedWallets?.length) {
  //   //   async function setWalletFromLocalStorage() {
  //   //     await connect({ autoSelect: previouslyConnectedWallets[0] })
  //   //   }
  //   //   setWalletFromLocalStorage()
  //   // }
  // }, [web3Onboard, connect])

  // // create an ethers provider
  // //let ethersProvider

  // // if (wallet) {
  // //   ethersProvider = new ethers.providers.Web3Provider(wallet.provider, "any")
  // // }

  //const [{ wallet }, connect, disconnect, updateBalances, setWalletModules] =
  //const [{ wallet }, connect] = useConnectWallet()
  //const [{ chains, connectedChain, settingChain }, setChain] = useSetChain()
  //const [notifications] = useNotifications()
  //const [notifications, customNotification, updateNotify] = useNotifications()
  const connectedWallets = useWallets()
  //const updateAccountCenter = useAccountCenter()
  //const updateLocale = useSetLocale()

  // eslint-disable-next-line
  const [web3Onboard, setWeb3Onboard] = useState(null)

  useEffect(() => {
    setWeb3Onboard(initWeb3Onboard)
  }, [])

  // useEffect(() => {
  //   //console.log("notifications: ", notifications)
  // }, [notifications])

  useEffect(() => {
    if (!connectedWallets.length) return

    const connectedWalletsLabelArray = connectedWallets.map(({ label }) => label)
    window.localStorage.setItem("connectedWallets", JSON.stringify(connectedWalletsLabelArray))
    setWalletConnected(true)
  }, [connectedWallets, wallet])

  useEffect(() => {
    const previouslyConnectedWallets = JSON.parse(window.localStorage.getItem("connectedWallets"))
    async function setWalletFromLocalStorage() {
      await connect({ autoSelect: previouslyConnectedWallets[0] })
    }

    if (previouslyConnectedWallets?.length) {
      setWalletFromLocalStorage()
      setWalletConnected(true)
    }
  }, [web3Onboard, connect])

  const handleSearchOnClick = () => {
    setSearchPlaceholderHidden(true)
  }
  const handleSearchInputHidden = () => {
    setSearchInputHidden(!searchInputHidden)
  }
  const handleSearchOnBlur = () => {
    !query && setSearchPlaceholderHidden(false)
    //setSearchPlaceholderHidden(false)
  }
  // const handleWalletModal = () => {
  //   connect()
  //   //walletModal(true)
  //   //   //setSearchPlaceholderHidden(false)
  //   //alert("1")
  // }

  const handleCloseDetails = () => {
    setSearchPlaceholderHidden(searchPlaceholderHidden)
    setSearchInputHidden(!searchInputHidden)
    setPixelDetailIsVisible(false)
  }
  const handleToggleDetails = () => {
    setSearchPlaceholderHidden(searchPlaceholderHidden)
    setSearchInputHidden(!searchInputHidden)
    setPixelDetailIsVisible(!pixelDetailIsVisible)
  }
  const handleToggleNotification = () => {
    setNotificationIsVisible(!notificationIsVisible)
    // setSearchPlaceholderHidden(searchPlaceholderHidden)
    // setSearchInputHidden(!searchInputHidden)
    // setPixelDetailIsVisible(!pixelDetailIsVisible)
  }

  // const handleQuery = (event) => {
  //   const { value } = event.target
  //   setQuery(value)
  // }

  const showNotifications = () => {
    setNotificationIsVisible(true)
    setPixelDetailIsVisible(false)
  }

  const purchaseInformation = (data) => {
    if (!data) return
    showNotifications()
    setNotificationData((prev) => [...prev, data])
  }

  //useEffect(() => {
  //  console.log(" --> NotificationData", notificationData)
  //}, [notificationData])

  useEffect(() => {
    if (!notificationIsLoading) return
    showNotifications()
  }, [notificationIsLoading])

  useEffect(() => {
    setSuggestions([])
    setHoverIndex(null)
    setNotificationIsVisible(false)
  }, [cellsSelectionStarted])
  useEffect(() => {
    if (hoverIndex === null) return
    if (suggestions?.length === 0) return
    if (!suggestions[hoverIndex]) {
      setHoverIndex(null)
      return
    }
    setQuery(suggestions[hoverIndex]["place_name"])
  }, [suggestions, hoverIndex])

  const handleKeyDown = (event) => {
    //console.log("event.key", event.key)

    const changeHoverIndex = async (direction) => {
      if (suggestions?.length < 1) return false

      if (direction === "increase") {
        if (hoverIndex === null) {
          setHoverIndex(0)
          return true
        }
        if (hoverIndex + 1 >= suggestions.length) return false
        setHoverIndex(hoverIndex + 1)
        return true
      }
      if (hoverIndex > 0) {
        setHoverIndex(hoverIndex - 1)
      }
    }

    if (event.key === "ArrowDown") {
      changeHoverIndex("increase")
    }
    if (event.key === "ArrowUp") {
      changeHoverIndex("decrease")
    }

    if (event.key === "Escape") {
      return false
    }
    if (event.key !== "Enter") {
      return false
    }

    // if (event.which == 33) {
    //   console.log("33")
    // }

    // if (event.which == 34) {
    //   console.log("34")
    // }
    //console.log("searching sent:", query)
    //setSearchQuery([(Math.random() - 0.5) * 360, (Math.random() - 0.5) * 100])
    //setSearchQuery([65, 44])
    //var latlng = query.split(/, ?/)
    //setSearchQuery(query)

    // var re = new RegExp("^([A-z0-9]{2,})$")
    // if (re.test(query)) {
    //   console.log("Valid string")
    // } else {
    //   console.log("Invalid string")
    // }

    //var matches = query.match(/([0-9.-]+).+?([0-9.-]+)/)
    const reLatLong = new RegExp(/([0-9.-]+).+?([0-9.-]+)/)
    let lat = 0
    let long = 0
    if (reLatLong.test(query)) {
      //console.log("Valid reLatLong")
      const matches = query.match(/([0-9.-]+).+?([0-9.-]+)/)
      lat = parseFloat(matches[1])
      long = parseFloat(matches[2])
      console.log("lat, long (coordinates): ", [lat, long])
      setSearchQuery([lat, long])
      clearSelectedPixels()
      setSuggestions([])
      setHoverIndex(null)
      setNotificationIsVisible(false)
    } else {
      console.log("Invalid reLatLong", query)

      //   try {
      //     const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${event.target.value}.json?access_token=pk.eyJ1Ijoic3RoeW1hIiwiYSI6ImNrcnFycDlzNjFxM3Uydm1vMGNxd200amsifQ.aTXBxeiEvrCesxbO8OuFEg&autocomplete=true`;
      //     const response = await fetch(endpoint);
      //     const results = await response.json();
      //     setSuggestions(results?.features);
      //   } catch (error) {
      //     console.log("Error fetching data, ", error);
      //   }

      axios
        .get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=pk.eyJ1IjoicG90YXBvdmQiLCJhIjoiY2w1MHUwanFzM205bjNjcXo0d2lzbGxhaSJ9.wCxBNXTKubVWi21TAipjnw&autocomplete=true`
        )
        .then(function (response) {
          // handle success
          console.log("response: ", response)
          //setSuggestions(response?.data.features)

          const features = response?.data.features
          if (features.length) {
            console.log("features[0]: ", features[0])
            if (features[0].bbox) {
              let lat = parseFloat(((features[0].bbox[0] + features[0].bbox[2] + features[0].center[0]) / 3) * 0.996)
              let long = parseFloat(((features[0].bbox[1] + features[0].bbox[3] + features[0].center[1]) / 3) * 1.0021)
              //lat = parseFloat(latTmp)
              //long = parseFloat(longTmp)
              console.log("lat, long (place - bbox): ", [lat, long])
              clearSelectedPixels()
              setSuggestions([])
              setHoverIndex(null)
              setNotificationIsVisible(false)
              setQuery(features[0].place_name)
              setSearchQuery([lat, long])
              //console.log("foo")

              return false
            }

            lat = parseFloat(features[0].center[0])
            long = parseFloat(features[0].center[1])
            console.log("lat, long (place - center): ", [lat, long])
            setQuery(features[0].place_name)
            setSearchQuery([lat, long])
            console.log("bar")
          }
        })
        .catch(function (error) {
          // handle error
          console.error("api mapbox Error: ", error)
        })
        .then(function () {
          // always executed
        })
    }
    //var lat = parseFloat(matches[1])
    //var long = parseFloat(matches[2])
  }

  const handleDisconnectWallet = (wallet) => {
    if (disconnect(wallet)) window.localStorage.removeItem("connectedWallets")
  }

  const handleChange = async (event) => {
    const { value } = event.target
    setQuery(value)

    try {
      //const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${event.target.value}.json?access_token=${mapboxApiKey}&autocomplete=true`
      const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${event.target.value}.json?access_token=${mapboxApiKey}&autocomplete=true&limit=7&routing=false&types=country,region`
      //const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${event.target.value}.json?access_token=pk.eyJ1Ijoic3RoeW1hIiwiYSI6ImNrcnFycDlzNjFxM3Uydm1vMGNxd200amsifQ.aTXBxeiEvrCesxbO8OuFEg&autocomplete=true`;
      const response = await fetch(endpoint)
      const results = await response.json()
      //console.log("=>results?.features", results?.features)
      setSuggestions(results?.features)

      // country -> text - place_type: ['country']
      // region -> context[0][text] - place_type: ['region']
    } catch (error) {
      console.log("Error fetching data, ", error)
    }
  }

  return (
    <div className="search-section">
      <div className="search-out">
        <div className="search-top">
          <div className="search-input-search-section">
            <div className={searchInputHidden && !latLon ? "search-input-out" : "search-input-out active"}>
              <div className={searchInputHidden && !latLon ? "search-input-section" : "search-input-section active"}>
                {/* <form autoComplete="off"> */}
                <input
                  className={searchInputHidden && !latLon ? "search-input-field" : "search-input-field active"}
                  onClick={handleSearchOnClick}
                  onBlur={handleSearchOnBlur}
                  type="search"
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  value={query || ""}
                  autoComplete="off"
                  role="presentation"
                  // {...address}
                  // isTyping={address.value !== ""}
                />
                {/* </form> */}
                <div
                  className={
                    searchPlaceholderHidden || (searchInputHidden && !latLon)
                      ? "search-input-placeholder hidden"
                      : "search-input-placeholder"
                  }
                >
                  Search a place
                  {svgSprites.searchInfo}
                  <span>Search by name, address or latitude and longitude</span>
                </div>
              </div>

              <div onClick={handleSearchInputHidden} title="Search" className="search-input-btn">
                {svgSprites.searchIcon}
              </div>
            </div>
            {suggestions?.length > 0 && (
              <div className="search-suggest">
                {suggestions.map((suggestion, index) => {
                  return (
                    <div
                      key={index}
                      className={hoverIndex === index ? "search-suggest-item hovered" : "search-suggest-item"}
                      onClick={() => {
                        //address.setValue(suggestion.place_name)
                        setQuery(suggestion.place_name)
                        sendCoordinates(suggestion.center)

                        // if (suggestion.place_type[0] === "country") {
                        //   //alert("country")
                        //   sendCountryName(suggestion.text)
                        // } else {
                        //   //alert("!country " + suggestion.context[0].text)
                        //   //alert(suggestion.context[0].text)
                        //   sendCountryName(suggestion.context[0].text)
                        // }
                        setSuggestions([])
                      }}
                    >
                      {suggestion.place_name}
                    </div>
                  )
                })}
              </div>
            )}
          </div>

          {/* Additional sections */}
          {/* {latLon && ( */}
          <div className="search-point-out" title="Details" onClick={handleToggleDetails}>
            {svgSprites.searchPoint}
          </div>
          {/* )} */}

          {latLon && (
            <div className="search-cart-out" title="Cart" onClick={handleToggleDetails}>
              <div className="search-cart-in">{svgSprites.SearchCart}</div>
            </div>
          )}

          <div className="search-point-out purple-border" title="Notifications" onClick={handleToggleNotification}>
            {svgSprites.NotificationBell}
          </div>

          {/* /Additional sections */}

          {/* <div
            className="wallet-btn-out"
            onClick={() => {
              connect()
            }}
          > */}
          <div
            className="wallet-btn-out"
            onClick={() => {
              wallet ? handleDisconnectWallet(wallet) : connect()
            }}
          >
            <div className="wallet-btn">
              {svgSprites.SearchWallet}
              {wallet ? "disconnect wallet" : "connect wallet"}
            </div>
          </div>
        </div>

        {/* notification center */}
        {notificationIsVisible && (
          <div className="search-notification">
            <div className="search-notification-box">
              <div className="search-notification-header">
                <div className="left-col">
                  <div className="search-notification-title">Notifications</div>
                  {/* TODO: do not remove this part */}
                  {/* <div className="search-notification-turn-off">
                    {svgSprites.NotificationMute}
                    <span>Turn off notifications</span>
                  </div> */}
                </div>
                <div className="right-col">
                  {/* TODO: do not remove this part */}
                  {notificationData?.status &&
                    {
                      /* <div className="search-notification-mark-as-read">
                      {svgSprites.NotificationAsRead}
                      <span>Mark as read</span>
                    </div> */
                    }}

                  {/* <div className="search-notification-mark-as-read">
                    {svgSprites.NotificationAsRead}
                    <span>Remove all notifications</span>
                  </div> */}
                </div>
              </div>
              <div className="search-notification-body">
                {/* {notificationData?.status && ( */}
                {notificationData.map((item, index) => (
                  <div key={index} className="search-notification-line">
                    <div className="search-notification-message-section">
                      <div>
                        <div className="search-notification-message-point point-active" />
                      </div>
                      <div>
                        <div className="search-notification-message-text">
                          {item.message}
                          {item.status === "ok" && (
                            <a className="search-notification-message-link" href={etherscan + item.tx}>
                              Check details
                            </a>
                          )}
                        </div>

                        <div className="search-notification-message-data">
                          {wallet && (
                            <span>
                              {String(wallet.accounts[0].address).substring(0, 5) +
                                "..." +
                                String(wallet.accounts[0].address).substring(38)}
                            </span>
                          )}{" "}
                          • {item.date}
                        </div>
                      </div>
                    </div>
                    <div className="search-notification-icon">
                      <div className="search-notification-icon-background">
                        {item.status !== "fail" ? (
                          <img src={notificationSuccessIcon} alt="Success!" />
                        ) : (
                          <img src={notificationFailIcon} alt="Fail!" />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                {!notificationData?.length && !notificationIsLoading && <div className="search-notification-empty">No Notification</div>}
                {notificationIsLoading && (
                  <div className="search-notification-loader">
                    <img src={loaderIcon} alt="Loading...." />
                  </div>
                )}

                {/* <div className="search-notification-line">
                  <div className="search-notification-message-section">
                    <div>
                      <div className="search-notification-message-point point-active" />
                    </div>
                    <div>
                      <div className="search-notification-message-text">Your purchase has been completed</div>
                      <div className="search-notification-message-data">
                        <span>0x84a08...</span> • Jul, 29, 2022 at 5:30pm
                      </div>
                    </div>
                  </div>
                  <div className="search-notification-icon">
                    <div className="search-notification-icon-background">
                      <img src={notificationSuccessIcon} alt="Success!" />
                    </div>
                  </div>
                </div>
                <div className="search-notification-line">
                  <div className="search-notification-message-section">
                    <div>
                      <div className="search-notification-message-point" />
                    </div>
                    <div>
                      <div className="search-notification-message-text">purchase failed</div>
                      <div className="search-notification-message-data">
                        <span>0x84a08...</span> • Jul, 29, 2022 at 5:30pm
                      </div>
                    </div>
                  </div>
                  <div className="search-notification-icon">
                    <div className="search-notification-icon-background">
                      <img src={notificationFailIcon} alt="Fail!" />
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="search-notification-footer">
                <div onClick={() => setNotificationIsVisible(false)} className="search-notification-toggler">
                  {svgSprites.SearchArrow}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className={pixelDetailIsVisible ? "search-details-info" : "search-details-info hidden"}>
          <SearchDetails
            rioPrice={rioPrice}
            ethPrice={ethPrice}
            latLon={latLon}
            selectedPixelsCount={selectedPixelsCount}
            placename={placenameDetails}
            selectedPixelsId={selectedPixelsId}
            clearSelectedPixels={clearSelectedPixels}
            selectionAreaIds={selectionAreaIds}
            selectionCellIds={selectionCellIds}
            purchaseInformation={purchaseInformation}
            notificationIsLoading={setNotificationIsLoading}
            authenticated={authenticated}
          />

          <div onClick={handleCloseDetails} className="search-arrow">
            {svgSprites.SearchArrow}
          </div>
        </div>
      </div>
    </div>
  )
}
export default SearchSection
