import { ReactComponent as SearchInfo } from "./assets/images/search-info.svg"
import { ReactComponent as SearchIcon } from "./assets/images/search-icon.svg"
import { ReactComponent as SearchPoint } from "./assets/images/search-point.svg"
import { ReactComponent as SearchCart } from "./assets/images/search-cart.svg"
import { ReactComponent as SearchWallet } from "./assets/images/wallet.svg"
import { ReactComponent as SearchArrow } from "./assets/images/search-arrow.svg"
import { ReactComponent as NotificationBell } from "./assets/images/notification-bell-icon.svg"
import { ReactComponent as NotificationMute } from "./assets/images/notification-mute-icon.svg"
import { ReactComponent as NotificationAsRead } from "./assets/images/notification-as-read-icon.svg"

const svgSprites = {
  searchInfo: <SearchInfo />,
  searchIcon: <SearchIcon />,
  searchPoint: <SearchPoint />,
  SearchCart: <SearchCart />,
  SearchWallet: <SearchWallet />,
  SearchArrow: <SearchArrow />,
  NotificationBell: <NotificationBell />,
  NotificationMute: <NotificationMute />,
  NotificationAsRead: <NotificationAsRead />,
}

export default svgSprites
