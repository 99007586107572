import React from "react"

function CounterItem(props) {
  return (
    <div className="counterItem">
      <div className="counterItem-container">
        <span>{props.time}</span>
      </div>
      <span className="counterItem-title">{props.title}</span>
    </div>
  )
}

export default CounterItem
