// social icons
import { ReactComponent as RealioLogo } from "./assets/images/realio-logo.svg"
import { ReactComponent as GithubLogo } from "./assets/images/github-logo.svg"
import { ReactComponent as MediumLogo } from "./assets/images/medium-logo.svg"
import { ReactComponent as TelegramLogo } from "./assets/images/telegram-logo.svg"
import { ReactComponent as TwitterLogo } from "./assets/images/twitter-logo.svg"
import { ReactComponent as InstagramLogo } from "./assets/images/ig-logo.svg"
import { ReactComponent as DiscordLogo } from "./assets/images/discord-logo.svg"

// export const uniswapTokenLink = "https://app.uniswap.org/#/swap?inputCurrency=0xf21661d0d1d76d3ecb8e1b9f1c923dbfffae4097&chain=mainnet"

// // const uMailchimp = "a4787730655ada57d89aa605f"
// // const idMailchimp = "53e223a7e9"
// // export const postUrlMailchimp = `https://fund.us19.list-manage.com/subscribe/post?u=${uMailchimp}&id=${idMailchimp}`

// // Hubspot
// export const hubspotRegion = "na1"
// export const hubspotPortalId = "21862563"
// export const hubspotFormId = "38f3466c-f7d0-4a22-955c-295de7b38e16"

// //API Keys
// export const mapApiKey = "AIzaSyBv93NgivKIqvgL1LA3rUBXX6qNstM7Mks"
// export const ethplorerApiKey = "EK-u8EDY-ZCto9Y9-LJJCm"
// export const algoApiKey = "3jqwWZlAkG7mUMoQnO9Bb2W85PG3xDU28TtrMjGs"

export const mapboxApiKey = "pk.eyJ1IjoibGFrY2htdSIsImEiOiJjazBleXA5dncwN2xsM2ltb2s5ejg4bDhnIn0.dAyHlJt5lrhHfCQjUA-gzQ"

// links
export const links = {
  site: "https://www.realio.fund/",
  twitter: "https://twitter.com/realio_network",
  telegram: "https://t.me/realio_fund",
  medium: "https://medium.com/@Realio_Network",
  linkedIn: "/",
  github: "https://github.com/realiotech",
  instagram: "https://www.instagram.com/realioverse/",
  discord: " https://discord.gg/WhRgHEfDF4",
}
// icons components
export const icons = {
  site: <RealioLogo />,
  twitter: <TwitterLogo />,
  telegram: <TelegramLogo />,
  medium: <MediumLogo />,
  github: <GithubLogo />,
  instagram: <InstagramLogo />,
  discord: <DiscordLogo />,
}
