import React, { useState, useEffect } from "react"

function Transfer(props) {
  const [countdown, setCountdown] = useState(5)

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1)
    }, 1000)

    if (countdown === 0) {
      clearInterval(timer)
      window.location.href = "http://www.districts.xyz"
    }

    return () => {
      clearInterval(timer)
    }
  }, [countdown])

  return (
    <div className="transfer">
      <div className="loader-1 loader1-center">
        <span></span>
      </div>
      <span className="transfer-text">Transferring you to the new website in {countdown}s</span>
    </div>
  )
}

export default Transfer
