//https://github.com/maptiler/maplibre-grid/blob/master/src/calc.js

import distance from "@turf/distance"
import destination from "@turf/destination"

//import distance from "@turf/distance"
//import destination from "@turf/destination"

//import bearing from "@turf/bearing"

// WGS84 EPSG:4326

// import destination from "@turf/rhumb-destination"
// import distance from "@turf/rhumb-distance"

/** @typedef {import('@turf/helpers').Units} Units */

/**
 * @param {GeoJSON.BBox} bbox
 * @param {number} gridWidth
 * @param {number} gridHeight
 * @param {Units} units
 * @returns {GeoJSON.Feature<GeoJSON.LineString>[]}
 */

// const latFix = (latitude) => {
//   //latitude = latitude.replace(".", ",")

//   const R = 40075
//   //console.log(" Math.cos(latitude)", latitude, Math.cos((parseFloat(latitude) * Math.PI) / 180))
//   const r = (R * Math.cos((parseFloat(latitude) * Math.PI) / 180)) / 360

//   // const X = r * 2 * Math.PI * ((longitude / 2) * Math.PI)
//   // //r * longitude [rad]
//   const Y = true
//   // //const Y = R * latitude [rad]
//   return r
// }

// const repositionPoint = (latLon, home) => {
//   const R = 6371 * 1000 // Earth radius in m
//   const circ = 2 * Math.PI * R // Circumference
//   const phi = 90 - latLon[1]
//   const theta = latLon[0] - home[0]
//   const thetaPrime = (home[1] / 180) * Math.PI
//   const x = R * Math.sin((theta / 180) * Math.PI) * Math.sin((phi / 180) * Math.PI)
//   const y = R * Math.cos((phi / 180) * Math.PI)
//   const z = R * Math.sin((phi / 180) * Math.PI) * Math.cos((theta / 180) * Math.PI)
//   const abs = Math.sqrt(z ** 2 + y ** 2)
//   const arg = Math.atan(y / z) - thetaPrime

//   return [x, Math.sin(arg) * abs]
// }

//const distanceWgs84 = function (coords1, coords2) {
// const distanceNew = function (coords1, coords2, options = null) {
//   // convert degrees to rads
//   var lon1 = (coords1[0] * Math.PI) / 180
//   var lon2 = (coords2[0] * Math.PI) / 180
//   var dLon = lon2 - lon1
//   var lat1 = (coords1[1] * Math.PI) / 180
//   var lat2 = (coords2[1] * Math.PI) / 180
//   var dLat = lat2 - lat1
//   var radius = 6371000
//   var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
//   var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
//   return (radius * c) / 1000
// }

// const distanceWgs84 = function (lat1, lon1, lat2, lon2, options = null) {
//   var p = 0.017453292519943295 // Math.PI / 180
//   var c = Math.cos
//   var a = 0.5 - c((lat2 - lat1) * p) / 2 + (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2

//   return 12742 * Math.asin(Math.sqrt(a)) // 2 * R; R = 6371 km
// }

// const haversineDistance = function (coords1, coords2, isMiles = false) {
//   //const distanceN = function (coords1, coords2, isMiles = false) {
//   function toRad(x) {
//     return (x * Math.PI) / 180
//   }

//   var lon1 = coords1[0]
//   var lat1 = coords1[1]

//   var lon2 = coords2[0]
//   var lat2 = coords2[1]

//   var R = 6371 // km

//   var x1 = lat2 - lat1
//   var dLat = toRad(x1)
//   var x2 = lon2 - lon1
//   var dLon = toRad(x2)
//   var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
//   var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
//   var d = R * c

//   if (isMiles) d /= 1.60934

//   return d
// }

// // const pythagoreanDistanceBetweenPoints = function (lat1, lon1, lat2, lon2, options = null) {
// //   const R = 6371e3
// //   const x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2)
// //   const y = lat2 - lat1
// //   const d = Math.sqrt(x * x + y * y) * R
// //   return d
// // }

// const haversineDistanceBetweenPoints = function (lat1, lon1, lat2, lon2, options = null) {
//   const R = 6371e3
//   const p1 = (lat1 * Math.PI) / 180
//   const p2 = (lat2 * Math.PI) / 180
//   const deltaLon = lon2 - lon1
//   const deltaLambda = (deltaLon * Math.PI) / 180
//   const d = Math.acos(Math.sin(p1) * Math.sin(p2) + Math.cos(p1) * Math.cos(p2) * Math.cos(deltaLambda)) * R
//   return d / 1000 //km
// }

// const cosineDistanceBetweenPoints = function (lat1, lon1, lat2, lon2, options = null) {
//   const R = 6371e3
//   const p1 = (lat1 * Math.PI) / 180
//   const p2 = (lat2 * Math.PI) / 180
//   const deltaP = p2 - p1
//   const deltaLon = lon2 - lon1
//   const deltaLambda = (deltaLon * Math.PI) / 180
//   const a =
//     Math.sin(deltaP / 2) * Math.sin(deltaP / 2) + Math.cos(p1) * Math.cos(p2) * Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2)
//   const d = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)) * R
//   return d / 1000 //km
// }

export function getGrid(bbox, gridWidth, gridHeight, units) {
  // return rectangleGrid(bbox, gridWidth, gridHeight, { units });

  const earthCircumference = Math.ceil(distance([0, 0], [180, 0], { units: units }) * 2)
  //console.log("earthCircumference", earthCircumference)
  const maxColumns = Math.floor(earthCircumference / gridWidth)
  /** @type {(from: GeoJSON.Position, to: GeoJSON.Position, options: { units: Units }) => number} */
  const fullDistance = (from, to, options) => {
    const dist = distance(from, to, options)
    if (Math.abs(to[0] - from[0]) >= 180) {
      return earthCircumference - dist
    }
    return dist
  }

  /** @type {GeoJSON.Feature<GeoJSON.LineString>[]} */
  const features = []
  const west = bbox[0]
  const south = bbox[1]
  const east = bbox[2]
  const north = bbox[3]

  // calculate grid start point
  const deltaX = (west < 0 ? -1 : 1) * fullDistance([0, 0], [west, 0], { units: units })
  const deltaY = (south < 0 ? -1 : 1) * fullDistance([0, 0], [0, south], { units: units })
  const startDeltaX = Math.ceil(deltaX / gridWidth) * gridWidth
  const startDeltaY = Math.ceil(deltaY / gridHeight) * gridHeight
  /** @type {GeoJSON.Position} */
  const startPoint = [
    destination([0, 0], startDeltaX, 90, { units: units }).geometry.coordinates[0],
    destination([0, 0], startDeltaY, 0, { units: units }).geometry.coordinates[1],
  ]

  // calculate grid columns and rows count
  const width = fullDistance([west, 0], [east, 0], { units: units })
  const height = fullDistance([0, south], [0, north], { units: units })
  //const height = width
  const columns = Math.min(Math.ceil(width / gridWidth), maxColumns)
  const rows = Math.ceil(height / gridHeight)

  // console.log("width, height", width, height)
  // console.log("columns, rows", columns, rows)

  /** @type {GeoJSON.Position} */
  let currentPoint

  // console.log("west", west)
  // console.log("east", east)
  // console.log("south", south)
  // console.log("north", north)
  // console.log("deltaY", deltaY)
  // console.log("startDeltaX", startDeltaX)
  // console.log("startDeltaY", startDeltaY)
  // console.log("destination", destination([0, 0], startDeltaX, 90, { units }).geometry.coordinates[0])
  // console.log("destination2", destination2([0, 0], startDeltaX, 90, { units }).geometry.coordinates[0])

  // console.log("distance", distance([0, 0], [180, 0], { units }))
  // console.log("distance2", distance2([0, 0], [180, 0], { units }))
  // //console.log("distanceN", distanceN([0, 0], [180, 0], { units }))
  // console.log("distanceWgs84", distanceWgs84(0, 0, 180, 0, { units }))
  // console.log("haversineDistanceBetweenPoints", haversineDistanceBetweenPoints(0, 0, 180, 0, { units }))
  // console.log("cosineDistanceBetweenPoints", cosineDistanceBetweenPoints(0, 0, 180, 0, { units }))
  // console.log("haversineDistance", haversineDistance([0, 0], [180, 0], false))
  //console.log("startPoint", startPoint)
  // //console.log("startPoint2", startPoint2)

  // meridians - horizontals
  currentPoint = startPoint
  for (let i = 0; i < columns; i++) {
    /** @type {GeoJSON.Position[]} */
    const coordinates = [
      [currentPoint[0], south],
      [currentPoint[0], north],
    ]
    /** @type {GeoJSON.Feature<GeoJSON.LineString>} */
    const feature = { type: "Feature", geometry: { type: "LineString", coordinates }, properties: {} }
    features.push(feature)

    //console.log("latFix currentPoint[0]", currentPoint[1], latFix(currentPoint[1]))

    currentPoint = [destination([currentPoint[0], 0], gridWidth, 90, { units: units }).geometry.coordinates[0], currentPoint[1]]

    //currentPoint = [currentPoint[0] + gridHeight, currentPoint[1]]
  }

  // parallels - verticals
  currentPoint = startPoint
  for (let i = 0; i < rows; i++) {
    /** @type {GeoJSON.Position[]} */
    const coordinates = [
      [west, currentPoint[1]],
      [east, currentPoint[1]],
    ]
    /** @type {GeoJSON.Feature<GeoJSON.LineString>} */
    const feature = { type: "Feature", geometry: { type: "LineString", coordinates }, properties: {} }
    features.push(feature)

    //console.log("[currentPoint[0], currentPoint[1] + gridWidth]", [currentPoint[0], currentPoint[1] + gridWidth])

    //console.log("latFix currentPoint[0]", currentPoint[0], latFix(currentPoint[0]))
    //console.log("latFix currentPoint[0]", currentPoint[0], latFix(currentPoint[0]))
    //console.log("latFix currentPoint[1]", currentPoint[1], latFix(currentPoint[1]))
    //console.log("latFix currentPoint[0]", currentPoint[0], latFix(currentPoint[0]))

    currentPoint = [currentPoint[0], destination([0, currentPoint[1]], gridHeight, 0, { units: units }).geometry.coordinates[1]]
    //currentPoint = [currentPoint[0], currentPoint[1] + gridWidth]
    //currentPoint = [currentPoint[0], currentPoint[1] + gridWidth]
  }

  return features
}

/**
 * @param {GeoJSON.Position} point
 * @param {number} gridWidth
 * @param {number} gridHeight
 * @param {Units} units
 * @returns {GeoJSON.BBox}
 */
export function getGridCell(point, gridWidth, gridHeight, units) {
  //const bbox = true
  const earthCircumference = Math.ceil(distance([0, 0], [180, 0], { units: units }) * 2)
  /** @type {(from: GeoJSON.Position, to: GeoJSON.Position, options: { units: Units }) => number} */
  const fullDistance = (from, to, options) => {
    const dist = distance(from, to, options)
    if (Math.abs(to[0] - from[0]) >= 180) {
      return earthCircumference - dist
    }
    return dist
  }

  const deltaX = (point[0] < 0 ? -1 : 1) * fullDistance([0, 0], [point[0], 0], { units: units })
  const deltaY = (point[1] < 0 ? -1 : 1) * fullDistance([0, 0], [0, point[1]], { units: units })
  const minDeltaX = Math.floor(deltaX / gridWidth) * gridWidth
  const minDeltaY = Math.floor(deltaY / gridHeight) * gridHeight
  const maxDeltaX = Math.ceil(deltaX / gridWidth) * gridWidth
  const maxDeltaY = Math.ceil(deltaY / gridHeight) * gridHeight
  const bbox = /** @type {GeoJSON.BBox} */ ([
    destination([0, 0], minDeltaX, 90, { units: units }).geometry.coordinates[0],
    destination([0, 0], minDeltaY, 0, { units: units }).geometry.coordinates[1],
    destination([0, 0], maxDeltaX, 90, { units: units }).geometry.coordinates[0],
    destination([0, 0], maxDeltaY, 0, { units: units }).geometry.coordinates[1],
  ])

  return bbox
}
