import React, { useState, useEffect } from "react"
import CounterItem from "./CounterItem"
// const now = new Date().getTime() //for testing
// const countdownDate = new Date(now + 5000).getTime()
function Counter(props) {
  const [days, setDays] = useState("00")
  const [hours, setHours] = useState("00")
  const [minutes, setMinutes] = useState("00")
  const [seconds, setSeconds] = useState("00")
  const [opacity, setOpacity] = useState(0)

  useEffect(() => {
    const countdownDate = new Date("December 1, 2023 11:00:00 EST").getTime()

    const updateCounter = () => {
      const now = new Date().getTime()
      const distance = countdownDate - now

      if (distance <= 0) {
        clearInterval(interval)
        props.counterFinished()
        setOpacity(0)
        return
      } else {
        setOpacity(1)
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24))
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((distance % (1000 * 60)) / 1000)

      setDays(days.toString().padStart(2, "0"))
      setHours(hours.toString().padStart(2, "0"))
      setMinutes(minutes.toString().padStart(2, "0"))
      setSeconds(seconds.toString().padStart(2, "0"))
    }

    const interval = setInterval(updateCounter, 1000)
    updateCounter()

    return () => {
      clearInterval(interval)
    }
  }, [props])

  return (
    <div className={`counter ${opacity ? "counter-show" : "counter-hide"}`}>
      <CounterItem time={days} title="Days" />
      <span className="counterItem-dots">:</span>
      <CounterItem time={hours} title="Hours" />
      <span className="counterItem-dots">:</span>
      <CounterItem time={minutes} title="Minutes" />
      <span className="counterItem-dots">:</span>
      <CounterItem time={seconds} title="Seconds" />
    </div>
  )
}

export default Counter
