import React from "react"

// import AnimatedNumber from "animated-number-react"

import LogoDesktop from "../images/logo-top.svg"
import LogoMobile from "../images/logo-small.svg"
// import MenuMobile from "../assets/images/hamburger-menu.svg"
// import MenuMobileSvg from "../assets/images/mobile-menu-back.svg"

import Socials from "../components/Socials"

import useWindowDimensions from "../helpers/UseWindowDimensions"

import { links, icons } from "../settings"
import { Link } from "react-router-dom"

const linksArray = [
  { link: links.site, img: icons.site },
  { link: links.github, img: icons.github },
  // { link: links.medium, img: icons.medium },
  { link: links.discord, img: icons.discord },
  { link: links.telegram, img: icons.telegram },
  { link: links.twitter, img: icons.twitter },
  { link: links.instagram, img: icons.instagram },
]

const HeaderFork = ({ circulating, holders, data }) => {
  // const [menuIsOpen, setMenuIsOpen] = useState(false)

  const { width } = useWindowDimensions()

  //console.log(circulating, holders, data)

  // const formatValue3 = (value) => value.toFixed(3)
  // const formatValue2 = (value) => value.toFixed(2)
  // const formatValue1 = (value) => value.toFixed(1)

  // function numbersFormatter(num, digits) {
  //   const lookup = [
  //     { value: 1, symbol: "" },
  //     { value: 1e3, symbol: "k" },
  //     { value: 1e6, symbol: "M" },
  //     { value: 1e9, symbol: "G" },
  //     { value: 1e12, symbol: "T" },
  //     { value: 1e15, symbol: "P" },
  //     { value: 1e18, symbol: "E" },
  //   ]
  //   const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  //   var item = lookup
  //     .slice()
  //     .reverse()
  //     .find(function (item) {
  //       return num >= item.value
  //     })
  //   return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0"
  // }

  return (
    <div className="header-section">
      <Link className="header-section-logo" to="/">
        {width > 991 ? <img src={LogoDesktop} className="top-logo" alt="" /> : <img src={LogoMobile} className="top-logo" alt="" />}
      </Link>
      {/* <div onClick={() => setMenuIsOpen(!menuIsOpen)} className="mobile-menu-btn">
        <img src={MenuMobile} alt="" />
      </div> */}
      <div className="links">
        {/* <div>
          <Link className="link" to="/land">
            LandRush
          </Link>
        </div> */}
        <div className="socials">
          <Socials data={linksArray} />
        </div>
      </div>
      {/* {menuIsOpen && ( */}
      {/* <div className={menuIsOpen ? "mobile-menu active" : "mobile-menu"}>
        <img onClick={() => setMenuIsOpen(!menuIsOpen)} className="mobile-menu-close" src={MenuMobileSvg} alt="" />

        {menuIsOpen && (
          <div className="mobile-menu-stats">
            <div className="mobile-menu-stats-line">
              <div className="mobile-menu-stats-name">price</div>
              <div className="mobile-menu-stats-value">
                $ <AnimatedNumber value={data.price} formatValue={formatValue3} />
                <span className={data.priceChanging >= 0 ? "token-stats-change positive-change" : "token-stats-change negative-change"}>
                  <AnimatedNumber value={data.priceChanging} formatValue={formatValue2} />%
                </span>
              </div>
            </div>
            <div className="mobile-menu-stats-line">
              <div className="mobile-menu-stats-name">mkt. cap</div>
              <div className="mobile-menu-stats-value">
                $ <AnimatedNumber value={numbersFormatter(data.price * circulating, 2)} formatValue={formatValue2} />
                {String(numbersFormatter(data.price * circulating, 2)).substr(-1)}
              </div>
            </div>
            <div className="mobile-menu-stats-line">
              <div className="mobile-menu-stats-name">24h volume</div>
              <div className="mobile-menu-stats-value">
                $ <AnimatedNumber value={numbersFormatter(data.dailyVolume, 2)} formatValue={formatValue2} />
                {String(numbersFormatter(data.dailyVolume, 2)).substr(-1)}
              </div>
            </div>
            <div className="mobile-menu-stats-line">
              <div className="mobile-menu-stats-name">holders</div>
              <div className="mobile-menu-stats-value">
                <AnimatedNumber value={numbersFormatter(holders, 2)} formatValue={formatValue1} />
                {String(numbersFormatter(holders, 2)).substr(-1)}
              </div>
            </div>
          </div>
        )}

        <a href={data.buyLink} target="_blank" rel="noopener noreferrer" className="mobile-menu-buy-btn">
          Get RIO
        </a>
        <div className="mobile-menu-socials">
          <Socials data={linksArray} />
        </div>
      </div> */}
      {/* )} */}
    </div>
  )
}
export default HeaderFork
