const Socials = ({ data }) => {
  return (
    <div data-testid="header-socials" className="socials-section">
      {data.map((item, index) => (
        <div key={index} className="social-item">
          <a target="_blank" rel="noreferrer" href={item.link}>
            {item.img}
          </a>
        </div>
      ))}
    </div>
  )
}
export default Socials
