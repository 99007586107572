import React, { useRef, useEffect, useState } from "react"
//import ReactMapboxGl from "react-mapbox-gl"
//import MapLayers from "./sections/MapLayers"
//import MapLayersNew from "./sections/MapLayersNew"
//import { squareGrid } from "@turf/turf"
//import distance from "@turf/distance"

//import "../../assets/styles/mapbox-gl.css"

//import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
//import "mapbox-gl-controls/lib/controls.css"

import Logo from "../../assets/images/logo-floating.svg"

import { Link } from "react-router-dom"
import SearchSection from "./SearchSection"

import { links, icons, mapboxApiKey } from "../../settings"
import Socials from "./sections/Socials"

// getGridCell
//getGridCell

//import axios from "axios"

//import * as MaplibreGrid from "maplibre-grid"
import * as MaplibreGrid from "../../helpers/MaplibreGrid"

import mapboxgl from "!mapbox-gl" // eslint-disable-line import/no-webpack-loader-syntax
import { getGridCell } from "../../helpers/MaplibreGridCalc"
import distance from "@turf/distance"
import axios from "axios"
import MapPassword from "./MapPassword"

//import { getGrid } from "../../helpers/MapHelper"
//import rectangleGrid from "../../helpers/MapHelperTurf"
mapboxgl.accessToken = mapboxApiKey

//import Maplibre from "maplibre-gl"
//import * as MaplibreGrid from "maplibre-grid"

//import mapboxgl from "mapbox-gl"
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default // line shows as uncovered

const minZoom = 5
const maxZoom = 17
const defaultZoom = 12
const gridHiddenZoomLvl = 10.8

//const gridId = "grid"
//const selectionId = "selectedPixels"
// const gridOptions = {
//   units: "kilometers",
// }
// const gridStyles = {
//   "fill-outline-color": "#ebebeb",
//   "fill-color": "transparent",
//   "fill-opacity": 1,
// }

// function debounce(f, ms) {
//   let isCoolDown = false

//   return function () {
//     if (isCoolDown) return

//     f.apply(this, arguments)
//     isCoolDown = true
//     setTimeout(() => (isCoolDown = false), ms)
//   }
// }

export default function MyMap(props) {
  const [lng, setLng] = useState(-74.120107)
  const [lat, setLat] = useState(40.697403)

  // const [lng, setLng] = useState(0)
  // const [lat, setLat] = useState(89)

  // const [lng, setLng] = useState(0.16)
  // const [lat, setLat] = useState(53.16)

  // const [lng, setLng] = useState(0)
  // const [lat, setLat] = useState(0)

  // //const [lng, setLng] = useState(19.03921)
  // //const [lat, setLat] = useState(47.495895)
  // const [zoom, setZoom] = useState(defaultZoom) // eslint-disable-line no-unused-vars
  // //const [optionsMap, setOptionsMap] = useState(null) // eslint-disable-line no-unused-vars
  // const [optionsSquareGrid, setOptionsSquareGrid] = useState(null) // eslint-disable-line
  // const [optionsOrigin, setOptionsOrigin] = useState(null) // eslint-disable-line
  // const [optionsCellWidth, setOptionsCellWidth] = useState(null) // eslint-disable-line
  // const [optionsCellHeight, setOptionsCellHeight] = useState(null) // eslint-disable-line
  // const [optionsGridHeight, setOptionsGridHeight] = useState(null) // eslint-disable-line
  //const [selectionStarted, setSelectionStarted] = useState(false) // eslint-disable-line
  // TODO can be changed firstSelectedPixel
  const [selectionCoordinatesStart, setSelectionCoordinatesStart] = useState(null) // eslint-disable-line
  // const [selection, setSelection] = useState([]) // eslint-disable-line
  // //const [eventClick, setEventClick] = useState([])
  // const [selectedMapSquares, setSelectedMapSquares] = useState([]) // eslint-disable-line

  // const [mouseMoveSelection, setMouseMoveSelection] = useState([]) // eslint-disable-line
  // const [selectionCoordinatesFinish, setSelectionCoordinatesFinish] = useState(null) // eslint-disable-line no-unused-vars
  // const [mapSquaresSelected, setMapSquaresSelected] = useState([]) // eslint-disable-line
  //const [currentSelectedPixel, setCurrentSelectedPixel] = useState([]) // eslint-disable-line
  //const [mouseMoveSelection, setMouseMoveSelection] = useState([]) // eslint-disable-line
  const [firstSelectedPixel, setFirstSelectedPixel] = useState([])
  const [selectedPixels, setSelectedPixels] = useState(0) // eslint-disable-line
  const [selectedPixelIsWater, setSelectedPixelIsWater] = useState(false)

  const [selectionAreaIds, setSelectionAreaIds] = useState([]) // eslint-disable-line
  const [selectionCellIds, setSelectionCellIds] = useState([])
  const [selectedCells, setSelectedCells] = useState([]) // eslint-disable-line
  const [ClearSelectedFlag, setClearSelectedFlag] = useState(false)
  const [cellsSelectionStarted, setCellsSelectionStarted] = useState(false)

  const [latDev, setLatDev] = useState(0)
  const [lngDev, setLngDev] = useState(0)
  const [zoomDev, setZoomDev] = useState(0)
  const [devPanel, setDevPanel] = useState(true) // eslint-disable-line

  const [map, setMap] = useState(null)
  const [grid, setGrid] = useState(null)
  const [mapLayer, setMapLayer] = useState("satellite")
  const [authenticated, setAuthenticated] = useState(false)

  const mapContainer = useRef(null)

  // const gridWidth = 0.00005
  // const gridHeight = 0.00005
  // const gridUnits = "radians"

  // const gridWidth = 0.005
  // const gridHeight = 0.005
  // const gridUnits = "degrees"

  const gridWidth = 1
  const gridHeight = 1
  const gridUnits = "kilometers"

  const selectedCellsId = "selected-cells"

  const refClearSelectedFlag = useRef(null)

  const selectedCellsRender = (map, selectedCellsId, selectedCellsArray) => {
    if (map.getLayer(selectedCellsId)) {
      //console.log("need to REMOVE getLayer")
      map.removeLayer(selectedCellsId)
    }
    if (map.getSource(selectedCellsId)) {
      //console.log("need to REMOVE getSource")
      map.removeSource(selectedCellsId)
    }
    ///console.log("time to ADD addSource, addLayer", map, selectedCellsId, selectedCellsArray)
    map.addSource(selectedCellsId, {
      type: "geojson",
      data: { type: "FeatureCollection", features: selectedCellsArray || [] },
    })
    map.addLayer({
      id: selectedCellsId,
      source: selectedCellsId,
      type: "fill",
      paint: {
        "fill-outline-color": "rgba(32, 12, 191, 1)",
        "fill-color": "#200CBF",
        "fill-opacity": 0.7,
      },
    })
  }

  const gridRender = (color = "white") => {
    let lineColor
    color === "white" ? (lineColor = "#ebebeb") : (lineColor = color)
    return new MaplibreGrid.Grid({
      gridWidth,
      gridHeight,
      units: gridUnits,

      paint: {
        "line-color": lineColor,
        "line-opacity": 0.45,
      },
    })
  }

  const changeMapStyles = (style) => {
    const updateMapLayer = (style) => {
      mapLayer === "satellite" ? setMapLayer("streetView") : setMapLayer("satellite")
      clearSelectedPixels()

      map.remove()
      return new mapboxgl.Map({
        container: mapContainer.current,
        minZoom: minZoom,
        maxZoom: maxZoom,
        style: `mapbox://styles/mapbox/${style}?optimize=true`,
        center: [lng, lat],
        zoom: defaultZoom,
        attributionControl: false,
        doubleClickZoom: false,
        dragRotate: false,
        antialias: false,
        clickTolerance: 1,
        hash: true,
        localFontFamily: "Poppins",
        optimizeForTerrain: false,
        projection: "equirectangular",
      })
    }

    let newMap = updateMapLayer(style)
    setMap(newMap)
  }

  const mapStyleHandle = () => {
    mapLayer === "satellite" ? changeMapStyles("outdoors-v11") : changeMapStyles("satellite-v9")
  }

  //initialize Map
  useEffect(() => {
    const initializeMap = ({ setMap, mapContainer }) => {
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        minZoom: minZoom,
        maxZoom: maxZoom,
        style: "mapbox://styles/mapbox/satellite-v9?optimize=true",
        center: [lng, lat],
        zoom: defaultZoom,
        attributionControl: false,
        doubleClickZoom: false,
        dragRotate: false,
        antialias: false,
        clickTolerance: 1,
        hash: true,
        localFontFamily: "Poppins",
        //optimizeForTerrain: false,
        //projection: "equirectangular",
      })

      map.addControl(
        new mapboxgl.NavigationControl({
          showCompass: false,
        }),
        "bottom-right"
      )

      setMap(map)
    }

    if (!map) initializeMap({ setMap, mapContainer })
  }, [map, grid, lng, lat])

  useEffect(() => {
    if (!map) return

    let selectionStarted = false
    let firstSelectedPixelMap = []
    let currentSelectedPixel = ""

    const featuresToIds = (selectedCellsArray) => {
      let selectedCellsArrayResult = []

      console.log("selectedCellsArray", selectedCellsArray)
      // eslint-disable-next-line
      selectedCellsArray.map((feature) => {
        const coordinates = feature.geometry.coordinates
        // eslint-disable-next-line
        coordinates.map((coordinate) => {
          const coordinate00String = coordinate[0][0].toFixed(6)
          const coordinate01String = coordinate[0][1].toFixed(6)
          const coordinate10String = coordinate[1][0].toFixed(6)
          const coordinate11String = coordinate[1][1].toFixed(6)
          const coordinate20String = coordinate[2][0].toFixed(6)
          const coordinate21String = coordinate[2][1].toFixed(6)
          const coordinate30String = coordinate[3][0].toFixed(6)
          const coordinate31String = coordinate[3][1].toFixed(6)

          selectedCellsArrayResult.push([
            `${coordinate01String},${coordinate00String},${coordinate11String},${coordinate10String},${coordinate21String},${coordinate20String},${coordinate31String},${coordinate30String}`,
          ])
          //console.log("selectedCellsArrayResult", selectedCellsArrayResult)
        })
      })
      setSelectionCellIds(selectedCellsArrayResult)
    }

    if (selectedCells.length === 0) {
      if (map.getLayer(selectedCellsId)) {
        map.removeLayer(selectedCellsId)
      }
      if (map.getSource(selectedCellsId)) {
        map.removeSource(selectedCellsId)
      }
    }

    const renderGridLayer = () => {
      //console.log("mapLayer", mapLayer)
      let gridLayer
      mapLayer === "satellite" ? (gridLayer = gridRender()) : (gridLayer = gridRender("#2d2b6f"))

      if (map.hasControl(gridLayer)) {
        map.removeControl(gridLayer)
      }
      map.addControl(gridLayer)
      setGrid(gridLayer)
    }

    map.on("load", () => {
      let selectedCellsArray = []
      renderGridLayer()

      window.setInterval(function () {
        //console.log("selectedCellsArray1", selectedCellsArray)
        if (refClearSelectedFlag.current?.checked) {
          selectedCellsArray.length = 0
          delete window.selectedCellsArray
          setClearSelectedFlag(false)
        }
      }, 700)

      if (!map.getLayer(selectedCellsId)) {
        selectedCellsRender(map, selectedCellsId, [])
      }

      map.on("zoom", () => {
        const zoom = map.getZoom().toFixed(6)
        setZoomDev(zoom)
        if (zoom <= gridHiddenZoomLvl) {
          //map.hasControl(grid) && map.removeControl(grid)
          map.getLayer(selectedCellsId) && map.setLayoutProperty(selectedCellsId, "visibility", "none")
        } else {
          //map.hasControl(grid) && map.addControl(grid)
          map.setLayoutProperty(selectedCellsId, "visibility", "visible")
        }
      })

      // onClick
      map.on("click", (event) => {
        const isWaterAction = async () => {
          const { lng, lat } = event.lngLat

          if (selectionStarted) {
            axios
              // https://onwater.io API key: &access_token=t2AiPSmaYdV42YDqEyDe
              // or http://api.geonames.org/oceanJSON?lat=-8.166014&lng=114.990214&username=kreideldev
              .get(`https://api.onwater.io/api/v1/results/${lat},${lng}`)
              .then(function (response) {
                const { data } = response
                setSelectedPixelIsWater(data.water)
              })
              .catch(function (error) {
                console.error("api onwater API Error: ", error)
              })
          }
        }
        isWaterAction()
      })

      map.on(MaplibreGrid.GRID_CLICK_EVENT, (event) => {
        const bbox = event.bbox
        const cellIndex = selectedCellsArray.findIndex((x) => x.geometry.bbox.toString() === bbox.toString())
        //console.log("bbox.toString()", bbox.toString())
        const coordinates = [
          [
            [bbox[0], bbox[1]], // A
            [bbox[2], bbox[1]], // B
            [bbox[2], bbox[3]], // C
            [bbox[0], bbox[3]], // D
            [bbox[0], bbox[1]], // A
          ],
        ]
        if (!selectionStarted) {
          //console.log("coordinates setFirstSelectedPixel:", coordinates)
          setFirstSelectedPixel(coordinates) // first click add coordinates
          firstSelectedPixelMap = coordinates
        }

        if (cellIndex === -1) {
          //const cellId = coordinates.toString().trim().replaceAll(".", "").replaceAll(",", "")
          // const cellId= temp.map(str => {
          //   return Number(str);
          // });
          //const cellCoordinates = coordinates.toString().trim().replaceAll("", "")
          //console.log("Cell Cordintes", cellCoordinates)
          //console.log("Cell Ids", cellId)
          const cell = {
            type: "Feature",
            geometry: { type: "Polygon", bbox, coordinates },
            // property: {
            //   id: cellId,
            //   coordinates: cellCoordinates,
            // },
          }
          selectedCellsArray.push(cell)
          // setSelectionAreaIds((selectionAreaIdsVar) => [...selectionAreaIdsVar, coordinates.toString()])
          //setSelectionCellIds((selectionCellIdsVar) => [...selectionCellIdsVar, cellId.toString()])
          setSelectionCoordinatesStart([bbox[0], bbox[1]])
        } else {
          if (!selectionStarted) {
            selectedCellsArray.splice(cellIndex, 1)
            selectionStarted = true
            setCellsSelectionStarted(true)
          }
        }
        //console.log("selectedCellsArray.length", selectedCellsArray.length)
        //console.log("selectedCellsArray", selectedCellsArray)
        setSelectedPixels(selectedCellsArray.length)
        setSelectedCells(selectedCellsArray)
        featuresToIds(selectedCellsArray)

        const source = map.getSource(selectedCellsId)
        if (source) {
          source.setData({ type: "FeatureCollection", features: selectedCellsArray })
        } else {
          if (!map.getLayer(selectedCellsId)) {
            selectedCellsRender(map, selectedCellsId, selectedCellsArray)
          }
        }

        selectionStarted = !selectionStarted
        setCellsSelectionStarted(!selectionStarted)
      })

      // onMouseMove
      map.on("mousemove", (event) => {
        const { lng, lat } = event.lngLat
        setLngDev(lng)
        setLatDev(lat)
        if (!selectionStarted) return
        if (!firstSelectedPixelMap.length) return

        const bbox = getGridCell([lng, lat], gridWidth, gridHeight, gridUnits)

        const coordinates = [
          [
            [bbox[0], bbox[1]],
            [bbox[2], bbox[1]],
            [bbox[2], bbox[3]],
            [bbox[0], bbox[3]],
            [bbox[0], bbox[1]],
          ],
        ]
        const firstPixelCoordinates = firstSelectedPixelMap[0]
        const hoverPixelCoordinates = coordinates[0]

        // const cell = {
        //   type: "Feature",
        //   geometry: { type: "Polygon", bbox, coordinates },
        // }

        if (firstPixelCoordinates.toString() === hoverPixelCoordinates.toString()) return
        if (hoverPixelCoordinates.toString() === currentSelectedPixel) return

        //console.log("firstPixelCoordinates -> ", firstPixelCoordinates)
        //console.log("hoverPixelCoordinates -> ", hoverPixelCoordinates)

        const pixelWidth = distance(
          [hoverPixelCoordinates[0][0], hoverPixelCoordinates[0][1]],
          [hoverPixelCoordinates[1][0], hoverPixelCoordinates[1][1]],
          { units: "degrees" }
        )
        const pixelHeight = distance(
          [hoverPixelCoordinates[0][0], hoverPixelCoordinates[0][1]],
          [hoverPixelCoordinates[3][0], hoverPixelCoordinates[3][1]],
          { units: "degrees" }
        )

        // to the right
        if (
          firstPixelCoordinates[0][1] === hoverPixelCoordinates[0][1] &&
          firstPixelCoordinates[2][1] === hoverPixelCoordinates[2][1] &&
          parseFloat(firstPixelCoordinates[0][0]) < parseFloat(hoverPixelCoordinates[0][0])
        ) {
          const selectedDistance = distance(
            [firstPixelCoordinates[0][0], firstPixelCoordinates[0][1]],
            [hoverPixelCoordinates[1][0], hoverPixelCoordinates[1][1]],
            {
              units: "degrees",
            }
          )
          const selectedPixels = Math.ceil(selectedDistance / pixelWidth)
          console.log(" => pixels: ", selectedPixels)

          for (let i = 0; i < selectedPixels; i++) {
            const bbox = getGridCell([lng, lat], gridWidth, gridHeight, gridUnits)
            const cellIndex = selectedCellsArray.findIndex((x) => x.geometry.bbox.toString() === bbox.toString())
            const coordinates = [
              [
                [bbox[0], bbox[1]],
                [bbox[2], bbox[1]],
                [bbox[2], bbox[3]],
                [bbox[0], bbox[3]],
                [bbox[0], bbox[1]],
              ],
            ]

            if (cellIndex === -1) {
              const cell = {
                type: "Feature",
                geometry: { type: "Polygon", bbox, coordinates },
              }

              selectedCellsArray.push(cell)
              //selectionCellIdsArray.push(coordinates)
            }
          }
        }

        // to the left
        if (
          firstPixelCoordinates[0][1] === hoverPixelCoordinates[0][1] &&
          firstPixelCoordinates[2][1] === hoverPixelCoordinates[2][1] &&
          parseFloat(firstPixelCoordinates[0][0]) > parseFloat(hoverPixelCoordinates[0][0])
        ) {
          const selectedDistance = distance(
            [firstPixelCoordinates[1][0], firstPixelCoordinates[1][1]],
            [hoverPixelCoordinates[0][0], hoverPixelCoordinates[0][1]],
            {
              units: "degrees",
            }
          )
          const selectedPixels = Math.ceil(selectedDistance / pixelWidth)
          console.log(" <= pixels: ", selectedPixels)

          for (let i = 0; i < selectedPixels; i++) {
            const bbox = getGridCell([lng, lat], gridWidth, gridHeight, gridUnits)
            const cellIndex = selectedCellsArray.findIndex((x) => x.geometry.bbox.toString() === bbox.toString())
            const coordinates = [
              [
                [bbox[0], bbox[1]],
                [bbox[2], bbox[1]],
                [bbox[2], bbox[3]],
                [bbox[0], bbox[3]],
                [bbox[0], bbox[1]],
              ],
            ]

            if (cellIndex === -1) {
              const cell = {
                type: "Feature",
                geometry: { type: "Polygon", bbox, coordinates },
              }

              selectedCellsArray.push(cell)
              //selectionCellIdsArray.push(coordinates)
            }
          }
        }

        // up
        if (
          firstPixelCoordinates[0][0] === hoverPixelCoordinates[0][0] &&
          firstPixelCoordinates[2][0] === hoverPixelCoordinates[2][0] &&
          parseFloat(firstPixelCoordinates[0][1]) < parseFloat(hoverPixelCoordinates[0][1])
        ) {
          const selectedDistance = distance(
            [firstPixelCoordinates[0][0], firstPixelCoordinates[1][1]],
            [hoverPixelCoordinates[3][0], hoverPixelCoordinates[3][1]],
            {
              units: "degrees",
            }
          )
          const selectedPixels = Math.ceil(selectedDistance / pixelHeight)
          console.log(" up pixels: ", selectedPixels)

          for (let i = 0; i < selectedPixels; i++) {
            const bbox = getGridCell([lng, lat], gridWidth, gridHeight, gridUnits)
            const cellIndex = selectedCellsArray.findIndex((x) => x.geometry.bbox.toString() === bbox.toString())
            const coordinates = [
              [
                [bbox[0], bbox[1]],
                [bbox[2], bbox[1]],
                [bbox[2], bbox[3]],
                [bbox[0], bbox[3]],
                [bbox[0], bbox[1]],
              ],
            ]

            if (cellIndex === -1) {
              const cell = {
                type: "Feature",
                geometry: { type: "Polygon", bbox, coordinates },
              }

              selectedCellsArray.push(cell)
              //selectionCellIdsArray.push(coordinates)
            }
          }
        }

        //down
        if (
          firstPixelCoordinates[0][0] === hoverPixelCoordinates[0][0] &&
          firstPixelCoordinates[2][0] === hoverPixelCoordinates[2][0] &&
          parseFloat(firstPixelCoordinates[0][1]) > parseFloat(hoverPixelCoordinates[0][1])
        ) {
          //console.log("down")
          const selectedDistance = distance(
            [firstPixelCoordinates[3][0], firstPixelCoordinates[3][1]],
            [hoverPixelCoordinates[0][0], hoverPixelCoordinates[0][1]],
            {
              units: "degrees",
            }
          )
          const selectedPixels = Math.ceil(selectedDistance / pixelHeight)
          console.log(" down pixels: ", selectedPixels)

          for (let i = 0; i < selectedPixels; i++) {
            const bbox = getGridCell([lng, lat], gridWidth, gridHeight, gridUnits)
            const cellIndex = selectedCellsArray.findIndex((x) => x.geometry.bbox.toString() === bbox.toString())
            const coordinates = [
              [
                [bbox[0], bbox[1]],
                [bbox[2], bbox[1]],
                [bbox[2], bbox[3]],
                [bbox[0], bbox[3]],
                [bbox[0], bbox[1]],
              ],
            ]

            if (cellIndex === -1) {
              const cell = {
                type: "Feature",
                geometry: { type: "Polygon", bbox, coordinates },
              }

              selectedCellsArray.push(cell)
              //selectionCellIdsArray.push(coordinates)
            }
          }
        }

        //diagonal
        if (
          firstPixelCoordinates[0][0] !== hoverPixelCoordinates[0][0] &&
          firstPixelCoordinates[0][1] !== hoverPixelCoordinates[0][1] &&
          firstPixelCoordinates[1][0] !== hoverPixelCoordinates[1][0] &&
          firstPixelCoordinates[1][1] !== hoverPixelCoordinates[1][1] &&
          firstPixelCoordinates[2][0] !== hoverPixelCoordinates[2][0] &&
          firstPixelCoordinates[2][1] !== hoverPixelCoordinates[2][1] &&
          firstPixelCoordinates[3][0] !== hoverPixelCoordinates[3][0] &&
          firstPixelCoordinates[3][1] !== hoverPixelCoordinates[3][1]
        ) {
          if (
            parseFloat(firstPixelCoordinates[0][0]) > parseFloat(hoverPixelCoordinates[0][0]) &&
            parseFloat(firstPixelCoordinates[0][1]) > parseFloat(hoverPixelCoordinates[0][1])
          ) {
            console.log("bottom left")

            // vertical
            const selectedDistanceVertical = distance(
              [firstPixelCoordinates[1][0], firstPixelCoordinates[1][1]],
              [firstPixelCoordinates[1][0], hoverPixelCoordinates[0][1]],
              {
                units: "degrees",
              }
            )

            // console.log(
            //   "bottom left - selectedDistanceVertical",
            //   [firstPixelCoordinates[1][0], firstPixelCoordinates[1][1]],
            //   [firstPixelCoordinates[1][0], hoverPixelCoordinates[0][1]]
            // )
            const selectedPixelsVertical = Math.round(selectedDistanceVertical / pixelHeight)
            console.log("bottom left - Vertical", selectedPixelsVertical)

            // horizontal
            const selectedDistanceHorizontal = distance(
              [firstPixelCoordinates[3][0], firstPixelCoordinates[3][1]],
              [firstPixelCoordinates[0][0], hoverPixelCoordinates[0][1]],
              {
                units: "degrees",
              }
            )

            // console.log(
            //   "bottom left - selectedDistanceHorizontal",
            //   [firstPixelCoordinates[1][0], firstPixelCoordinates[1][1]],
            //   [firstPixelCoordinates[0][0], hoverPixelCoordinates[0][1]]
            // )
            const selectedPixelsHorizontal = Math.round(selectedDistanceHorizontal / pixelWidth)
            console.log("bottom left - Horizontal", selectedPixelsHorizontal)

            for (let horizontal = 0; horizontal <= selectedPixelsHorizontal; horizontal++) {
              for (let vertical = 0; vertical <= selectedPixelsVertical; vertical++) {
                const bbox = getGridCell(
                  [
                    firstPixelCoordinates[2][0] - pixelWidth - pixelWidth * horizontal,
                    firstPixelCoordinates[2][1] - pixelHeight - pixelHeight * vertical,
                  ],
                  gridWidth,
                  gridHeight,
                  gridUnits
                )

                const cellIndex = selectedCellsArray.findIndex((x) => x.geometry.bbox.toString() === bbox.toString())
                const coordinates = [
                  [
                    [bbox[0], bbox[1]],
                    [bbox[2], bbox[1]],
                    [bbox[2], bbox[3]],
                    [bbox[0], bbox[3]],
                    [bbox[0], bbox[1]],
                  ],
                ]
                //console.log("bbox", coordinates)

                if (cellIndex === -1) {
                  const cell = {
                    type: "Feature",
                    geometry: { type: "Polygon", bbox, coordinates },
                  }

                  selectedCellsArray.push(cell)
                  //selectionCellIdsArray.push(coordinates)
                }
              }
            }
          }
          if (
            parseFloat(firstPixelCoordinates[0][0]) < parseFloat(hoverPixelCoordinates[0][0]) &&
            parseFloat(firstPixelCoordinates[0][1]) > parseFloat(hoverPixelCoordinates[0][1])
          ) {
            //console.log("bottom right")
            // vertical
            const selectedDistanceVertical = distance(
              [firstPixelCoordinates[3][0], firstPixelCoordinates[3][1]],
              [firstPixelCoordinates[3][0], hoverPixelCoordinates[1][1]],
              {
                units: "degrees",
              }
            )

            // console.log(
            //   "bottom right - selectedDistanceVertical",
            //   [firstPixelCoordinates[3][0], firstPixelCoordinates[3][1]],
            //   [firstPixelCoordinates[3][0], hoverPixelCoordinates[1][1]]
            // )
            const selectedPixelsVertical = Math.round(selectedDistanceVertical / pixelHeight)
            console.log("bottom right - Vertical", selectedPixelsVertical)

            // horizontal
            const selectedDistanceHorizontal = distance(
              [firstPixelCoordinates[3][0], firstPixelCoordinates[3][1]],
              [hoverPixelCoordinates[1][0], firstPixelCoordinates[1][1]],

              {
                units: "degrees",
              }
            )

            console.log(
              "bottom right - selectedDistanceHorizontal",
              [firstPixelCoordinates[3][0], firstPixelCoordinates[3][1]],
              [hoverPixelCoordinates[1][1], firstPixelCoordinates[1][1]]
            )
            const selectedPixelsHorizontal = Math.round(selectedDistanceHorizontal / pixelWidth)
            console.log("bottom right - Horizontal", selectedPixelsHorizontal)

            for (let horizontal = 0; horizontal <= selectedPixelsHorizontal; horizontal++) {
              for (let vertical = 0; vertical <= selectedPixelsVertical; vertical++) {
                const bbox = getGridCell(
                  [
                    firstPixelCoordinates[3][0] + pixelWidth + pixelWidth * horizontal,
                    firstPixelCoordinates[3][1] - pixelHeight * vertical,
                  ],
                  gridWidth,
                  gridHeight,
                  gridUnits
                )

                const cellIndex = selectedCellsArray.findIndex((x) => x.geometry.bbox.toString() === bbox.toString())
                const coordinates = [
                  [
                    [bbox[0], bbox[1]],
                    [bbox[2], bbox[1]],
                    [bbox[2], bbox[3]],
                    [bbox[0], bbox[3]],
                    [bbox[0], bbox[1]],
                  ],
                ]
                //console.log("bbox", coordinates)

                if (cellIndex === -1) {
                  const cell = {
                    type: "Feature",
                    geometry: { type: "Polygon", bbox, coordinates },
                  }

                  selectedCellsArray.push(cell)
                  //selectionCellIdsArray.push(coordinates)
                }
              }
            }
          }
          if (
            parseFloat(firstPixelCoordinates[0][0]) < parseFloat(hoverPixelCoordinates[0][0]) &&
            parseFloat(firstPixelCoordinates[0][1]) < parseFloat(hoverPixelCoordinates[0][1])
          ) {
            // vertical
            const selectedDistanceVertical = distance(
              [firstPixelCoordinates[3][0], firstPixelCoordinates[3][1]],
              [firstPixelCoordinates[0][0], hoverPixelCoordinates[3][1]],
              {
                units: "degrees",
              }
            )

            // console.log(
            //   "top right - selectedDistanceVertical",
            //   [firstPixelCoordinates[3][0], firstPixelCoordinates[3][1]],
            //   [firstPixelCoordinates[0][0], hoverPixelCoordinates[3][1]]
            // )
            const selectedPixelsVertical = Math.round(selectedDistanceVertical / pixelHeight)
            console.log("top right - Vertical", selectedPixelsVertical)

            // horizontal
            const selectedDistanceHorizontal = distance(
              [firstPixelCoordinates[0][0], firstPixelCoordinates[0][1]],
              [hoverPixelCoordinates[1][0], firstPixelCoordinates[0][1]],
              {
                units: "degrees",
              }
            )

            // console.log(
            //   "top right - selectedDistanceHorizontal",
            //   [firstPixelCoordinates[0][0], firstPixelCoordinates[0][1]],
            //   [hoverPixelCoordinates[1][0], firstPixelCoordinates[0][1]]
            // )
            const selectedPixelsHorizontal = Math.round(selectedDistanceHorizontal / pixelWidth)
            console.log("top right - Horizontal", selectedPixelsHorizontal)

            for (let horizontal = 0; horizontal <= selectedPixelsHorizontal; horizontal++) {
              for (let vertical = 0; vertical <= selectedPixelsVertical; vertical++) {
                const bbox = getGridCell(
                  [
                    firstPixelCoordinates[0][0] + pixelWidth + pixelWidth * horizontal,
                    firstPixelCoordinates[0][1] + pixelHeight + pixelHeight * vertical,
                  ],
                  gridWidth,
                  gridHeight,
                  gridUnits
                )

                const cellIndex = selectedCellsArray.findIndex((x) => x.geometry.bbox.toString() === bbox.toString())
                const coordinates = [
                  [
                    [bbox[0], bbox[1]],
                    [bbox[2], bbox[1]],
                    [bbox[2], bbox[3]],
                    [bbox[0], bbox[3]],
                    [bbox[0], bbox[1]],
                  ],
                ]
                //console.log("bbox", coordinates)

                if (cellIndex === -1) {
                  const cell = {
                    type: "Feature",
                    geometry: { type: "Polygon", bbox, coordinates },
                  }

                  selectedCellsArray.push(cell)
                  //selectionCellIdsArray.push(coordinates)
                }
              }
            }
            //console.log("selectedCellsArray", selectedCellsArray)
          }
          if (
            parseFloat(firstPixelCoordinates[0][0]) > parseFloat(hoverPixelCoordinates[0][0]) &&
            parseFloat(firstPixelCoordinates[0][1]) < parseFloat(hoverPixelCoordinates[0][1])
          ) {
            //console.log("top left")

            //vertical
            const selectedDistanceVertical = distance(
              [firstPixelCoordinates[1][0], firstPixelCoordinates[2][1]],
              [firstPixelCoordinates[2][0], hoverPixelCoordinates[2][1]],
              {
                units: "degrees",
              }
            )
            // console.log(
            //   "top left - selectedDistanceVertical",
            //   [firstPixelCoordinates[1][0], firstPixelCoordinates[2][1]],
            //   [firstPixelCoordinates[2][0], hoverPixelCoordinates[2][1]]
            // )
            const selectedPixelsVertical = Math.round(selectedDistanceVertical / pixelHeight)
            console.log("top left - Vertical", selectedPixelsVertical)

            //horizontal
            const selectedDistanceHorizontal = distance(
              [firstPixelCoordinates[1][0], firstPixelCoordinates[1][1]],
              [hoverPixelCoordinates[0][0], firstPixelCoordinates[0][1]],
              {
                units: "degrees",
              }
            )

            // console.log(
            //   "top left - selectedDistanceHorizontal",
            //   [firstPixelCoordinates[1][0], firstPixelCoordinates[1][1]],
            //   [hoverPixelCoordinates[0][0], firstPixelCoordinates[0][1]]
            // )
            const selectedPixelsHorizontal = Math.round(selectedDistanceHorizontal / pixelWidth)
            console.log("top left - Horizontal", selectedPixelsHorizontal)

            for (let horizontal = 0; horizontal <= selectedPixelsHorizontal; horizontal++) {
              for (let vertical = 0; vertical <= selectedPixelsVertical; vertical++) {
                const bbox = getGridCell(
                  [
                    firstPixelCoordinates[1][0] - pixelWidth - pixelWidth * horizontal,
                    firstPixelCoordinates[1][1] + pixelHeight + pixelHeight * vertical,
                  ],
                  gridWidth,
                  gridHeight,
                  gridUnits
                )

                const cellIndex = selectedCellsArray.findIndex((x) => x.geometry.bbox.toString() === bbox.toString())
                const coordinates = [
                  [
                    [bbox[0], bbox[1]],
                    [bbox[2], bbox[1]],
                    [bbox[2], bbox[3]],
                    [bbox[0], bbox[3]],
                    [bbox[0], bbox[1]],
                  ],
                ]
                //console.log("bbox", coordinates)

                if (cellIndex === -1) {
                  const cell = {
                    type: "Feature",
                    geometry: { type: "Polygon", bbox, coordinates },
                  }

                  selectedCellsArray.push(cell)
                  //selectionCellIdsArray.push(coordinates)
                }
              }
            }
            // console.log("selectedCellsArray", selectedCellsArray)
          }
        }

        currentSelectedPixel = hoverPixelCoordinates.toString()
        const source = map.getSource(selectedCellsId)
        if (source) {
          source.setData({ type: "FeatureCollection", features: selectedCellsArray })
        } else {
          if (!map.getLayer(selectedCellsId)) {
            selectedCellsRender(map, selectedCellsId, selectedCellsArray)
          }
        }

        setSelectedPixels(selectedCellsArray.length)
        setSelectedCells(selectedCellsArray)
        featuresToIds(selectedCellsArray)
      })
    })
  }, [map, selectedCells, firstSelectedPixel, mapLayer, selectedPixelIsWater])

  function clearSelectedPixels() {
    const cleanFunction = async () => {
      setSelectedPixels(0)
      setSelectionAreaIds([])
      setSelectionCellIds([])
      setSelectedCells([])
      setClearSelectedFlag(true)
    }
    cleanFunction()
  }

  const linksArray = [
    { link: links.site, img: icons.site },
    { link: links.github, img: icons.github },
    { link: links.medium, img: icons.medium },
    { link: links.telegram, img: icons.telegram },
    { link: links.twitter, img: icons.twitter },
    { link: links.instagram, img: icons.instagram },
  ]
  // eslint-disable-next-line
  const [walletSelectionActive, setWalletSelectionActive] = useState(false)
  //const [activePixels, setActivePixels] = useState(false)

  function setMapCenter(coords) {
    if (!coords) return
    console.log(coords)
    setLng(coords[0])
    setLat(coords[1])
    map.setCenter([coords[0], coords[1]])
  }

  const getSearchCoordinates = (coordinates) => {
    if (!map) return

    const center = new mapboxgl.LngLat(coordinates[0], coordinates[1])
    map.setCenter(center)
  }

  return (
    <div data-testid="map-layout" style={{ position: "relative", height: "100vh", width: "100%" }}>
      <div className="page-wrapper">
        <Link to={"/"}>
          <img src={Logo} className="floating-logo" alt="" />
        </Link>
        <div className="globe-search">
          <div className="globe-search-inner search-section">
            <SearchSection
              collapsed={true}
              isGlobe={false}
              //activePixels={activePixels}
              //activePixels={true}
              walletModal={setWalletSelectionActive}
              latLon={selectionCoordinatesStart}
              selectedPixelsCount={selectedPixels}
              //clearSelectedPixels={false}
              //sendCoordinates={false}
              clearSelectedPixels={clearSelectedPixels}
              sendCoordinates={getSearchCoordinates}
              selectionAreaIds={selectionAreaIds}
              //selectionCellIds={selectionCellIds}
              selectionCellIds={selectionCellIds}
              cellsSelectionStarted={cellsSelectionStarted}
              //activePixels={true}
              // walletModal={() => {
              //   connect()
              // }}
              setSearchQuery={setMapCenter}
              authenticated={authenticated}
            />
          </div>
        </div>
        <div className="socials-floating">
          <Socials data={linksArray} />
        </div>
        {/* <div className="map-fullscreen" id="map" ref={ref} /> */}

        <div style={{ position: "absolute", height: "100%", width: "100%", top: 0, padding: 0, margin: 0 }}>
          {/* <div ref={mapContainerRef} className="map-container map-fullscreen" /> */}
          <div ref={(el) => (mapContainer.current = el)} className="map-container map-fullscreen" />
          {!authenticated && <MapPassword setAuthenticated={setAuthenticated} />}
        </div>

        <div onClick={mapStyleHandle} className="map-style-toggler">
          {mapLayer === "satellite" ? "Street View" : "Satellite"}
        </div>

        {devPanel && (
          <div
            style={{
              position: "fixed",
              bottom: "0px",
              left: "0px",
              borderRadius: "10px",
              zIndex: 1000,
              backgroundColor: "#0c086c",
              opacity: 0.9,
              padding: "10px",
              fontSize: "12px",
            }}
          >
            <b>Clear Flag:</b> &nbsp;
            <input
              type="checkbox"
              readOnly
              ref={refClearSelectedFlag}
              checked={ClearSelectedFlag}
              onChange={() => {
                return
              }}
            />
            &nbsp; &nbsp;
            <b>Lng:</b> {lngDev.toFixed(6)} &nbsp;
            <b>Lat:</b> {latDev.toFixed(6)} &nbsp;
            <b>Zoom:</b> {zoomDev} &nbsp;
            <b>isWater:</b> {selectedPixelIsWater ? "true" : "false"}
          </div>
        )}
      </div>
    </div>
  )
}
